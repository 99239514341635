@import 'config/colors.scss';

.popoverContainer {
  color: $white;
  background-color: #333;
  padding: 12px;
  font-size: 14px;
  line-height: 1.35;
  opacity: 0.9;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);

  > p {
    margin-bottom: 6px;
    &:last-child { margin-bottom: 0; }
  }
}