@import 'config/colors.scss';	

body, * {	
  margin: 0;	
  padding: 0;	
  font-family: 'Inter', sans-serif;	
}	

.fadeOnTouch {	
  opacity: 1;	
  cursor: pointer;	
}	

.fadeOnTouch:active {	
  opacity: 0.3;	
}	
