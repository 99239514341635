@import 'config/colors.scss';

.manageItems {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.manageItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  cursor: pointer;
  border-radius: 8px;
  background-color: white;
  text-decoration: none;
  box-shadow: 0 .5rem 1rem rgba($black, 0.1);
}

.manageItem:hover {
  background-color: $primary;
  color: white;
}

.manageItem .innerIcon {
  color: $primary;
  font-size: 4rem;
}

.manageItem .manageItemTitle {
  display: block;
  color: $text;
  font-weight: 500;
  font-size: 0.9rem;
}

.manageItem:hover > span { color: $white };
