
.drop-down__value-container {
  padding: 0 6px !important;
}

.drop-down__menu-list {
  padding: 0 !important;
}

.drop-down__option {
  padding: 6px 12px !important;
  color: #666 !important;
  cursor: pointer !important;
}

.drop-down__option--is-selected {
  color: #fff !important;
}