@import 'config/colors.scss';

.tab-button {
  background-color: $background-light-2;
  &:hover {
    background-color: $white;
  }

  &--active {
    background-color: $white;
  }
}