@import 'config/colors.scss';

.ts-react-select__control {
  background-color: white !important;
  color: #999999 !important;
  /* max-height: 32px !important;
  min-height: 32px !important; */
  min-width: 98px;
  box-shadow: none;
}

.ts-react-select__control--is-focused {
  border-color: #cccccc !important;
  box-shadow: none !important;
}

.ts-react-select__value-container {
  cursor: pointer;
  /* line-height: 32px; */
  font-size: 1em !important;
  /* height: 32px !important; */
}

.ts-react-select__single-value {
  width: 100%;
  font-size: 1em !important;
}

.ts-react-select__indicators {
  width: 30px !important;
}

.ts-react-select__option {
  color: #999999 !important;
  cursor: pointer !important;
}

.ts-react-select__option--is-selected {
  background-color: $positive !important;
  color: #fff !important;
}

.ts-react-select__placeholder {
  font-size: 1em !important;
  overflow: hidden !important;
}
