@import 'config/colors.scss';
@import 'config/fonts.scss';

.manage-modal__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  color: $text;
  font-size: $text-main-size;
}

.manage-modal__content-container {
  padding: 24px;
  flex: 1;
}

.manage-modal__container > * {
  color: $text;
}

.manage-modal__list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.manage-modal__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.manage-modal__item-text {
  font-weight: $header-sub-weight;
}

.manage-modal__summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.manage-modal__summary--full-height {
  flex: 1;
}

.manage-modal__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  border-top: 1px solid $line;
  padding: 12px;
}