@import 'config/colors.scss';

html {
  touch-action: manipulation;
}

html, body, #root, #root > div:not([class]), #root > div[class=""] {
  margin: 0;
  padding: 0;
  font-family: Inter sans-serif;
  height: 100%;
  overflow: hidden;
  font-size: 87.50%;
}

.error-text {
  font-size: 0.9rem;
  color: $error;
  font-weight: 500;;
}

.firebase-emulator-warning {
  display: none !important;
}

.intercom-launcher,
.intercom-launcher-frame {
  left: 10px !important;
  bottom: 10px !important;
}

/* SCROLL DOWN ARROW */

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.space-children-24 {
  > * {
    margin-right: 24px;
    &:last-child { margin-right: 0; }
  }

  &--bottom {
    > * {
      margin-bottom: 24px;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.space-children-12 {
  > * {
    margin-right: 12px;
    &:last-child { margin-right: 0; }
  }

  &--bottom {
    > * {
      margin-bottom: 12px;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.space-children-6 {
  > * {
    margin-right: 6px;
    &:last-child { margin-right: 0; }
  }

  &--bottom {
    > * {
      margin-bottom: 6px;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.space-children-3 {
  > * {
    margin-right: 3px;
    &:last-child { margin-right: 0; }
  }

  &--bottom {
    > * {
      margin-bottom: 3px;
      &:last-child { margin-bottom: 0; }
    }
  }
}

/* SCROLLING HELPERS */

.scrollable { overflow: auto; }
.v-scrollable { overflow-y: auto; }
.h-scrollable { overflow-x: auto; }

/* FLEX HELPERS */

.v-flex-1 {
  flex: 1 1 0px;
  height: 0px;
  width: 100%;
}

.h-flex-1 {
  flex: 1 1 0px;
  width: 0px;
  height: 100%;
}

.flex-0 {
  flex: 0 0 auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* INPUT */

input:placeholder-shown,
input::-webkit-input-placeholder,
input:-moz-placeholder ,
input::-moz-placeholder ,
input:-ms-input-placeholder  {
  color: #cccccc;
  font-style: italic;
}

input {
  color: #666666;
  font-style: normal;
}

.pinkFooter {
  background-color: $positive;
}

.pinkFooter a:link,
.pinkFooter a:visited,
.pinkFooter a:active,
.whiteLinks a:link,
.whiteLinks a:visited,
.whiteLinks a:active {
  color: white !important;
}

/* Select component styles */
.react-select__control {
  // margin-bottom: 6px;
  background-color: white !important;
  /* max-height: 32px !important;
  min-height: 32px !important; */
}

.react-select__value-container {
  cursor: pointer;
  /* height: 10px !important;
  line-height: 32px; */
}

.react-select__single-value {
  /* width: 100%; */
}

.react-select__option {
  color: #999999 !important;
  cursor: pointer !important;
}

// .react-select__option--is-selected {
//   background-color: $primary !important;
//   color: #fff !important;
// }


/* End select styles */

.react-tiny-popover-container {
  z-index: 999999999;
}

.ReactModal__Content {
  opacity: 0;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  transition: all 0.25s ease;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.2) 0px 3px 6px 0px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.25s ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.shadow {
  box-shadow: 0 2px 4px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08);
  transition: all .15s ease;
  outline: none;
  cursor: pointer;
}

.shadow * {
  cursor: pointer;
}

.shadow:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08);
}

.shadow:active {
  transform: translateY(1px);
}

.rise-ts {
  cursor: pointer;
}

.rise-ts:hover {
  box-shadow: 0 2px 10px -3px #4f4f4f;
}

.rise-ts:active {
  transform: translateY(1px);
  box-shadow: 0 1px 10px -3px #4f4f4f !important;
}

.rise {
  cursor: pointer;
}

.rise:hover {
  transform: translateY(-1px);
}

.rise:active {
  transform: translateY(1px);
}

.ReactModal__Content .rise,
.ReactModal__Content .shadow {
  transition: none !important;
}

.highlightIcon:hover {
  color: $positive !important;
}

.clickableText {
  opacity: 1;
  cursor: pointer;
}

.clickableText:hover {
  opacity: 0.5;
}

::-webkit-scrollbar {
    display: none;
}

.hideIeScrolls {
  -ms-overflow-style: none;
}

.animationOff {
  transition: none !important;
}

.switch.on {
  background: #33cd5f !important;
}

.balanced {
  color: #33cd5f;
}

.positive {
  color: $positive;
}

.danger {
  color: #e06666;
}

.warning {
  color: #f6b26b;
}

.fadeInAndOut {
  -webkit-animation: fadeinout 5s linear forwards;
  animation: fadeinout 5s linear forwards;
}

@-webkit-keyframes fadeinout {
0%,100% { opacity: 0; }
20%,80% { opacity: 1; }
}

@keyframes fadeinout {
0%,100% { opacity: 0; }
20%,80% { opacity: 1; }
}
.rollover,
.rollover * {
  cursor: pointer;
  transition: 0.25s background-color,color ease;
}

.rollover:hover {
  background-color: #eaeaea;
  color: $positive;
}

.rollover:active {
  background-color: #cccccc;
  color: $positive;
}

.rise:disabled,
.shadow:disabled,
.smallButton:disabled {
  opacity: 0.5;
  transform: none;
}

input[type='file'] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

input[type='file'] + label {
  border: none;
  border-radius: 6px;
  color: white;
  outline: 0;
  padding: 6px 12px;
  min-width: 100px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.failureMessage {
  padding: 20px;
  color: #666;
}

.inviteModalScroll {
  max-height: 300;
  min-height: 10px;
}

.heading-link {
  display: inline-block;
  margin-right: 3px;
  color: white;
  cursor: pointer;
}

.heading-link:hover {
  text-decoration: underline;
}

.h-underline:hover {
  text-decoration: underline;
}

a.link {
  font-size: inherit;
  color: $text;
  cursor: pointer;
  &:hover { text-decoration: underline; }
}

@media (max-width: 500px) {
  .m-hide {
    display: none !important;
  }
}


@media print {
  .p-hide {
    display: none !important;
  }

  html, body, div {
    overflow: visible !important;
  }
}

::selection {
  color: $white;
  background-color: $primary;
}