@import 'config/colors.scss';
@import 'config/spacing.scss';

.split-bar-button {
  flex: 1;
  padding: 6px 0px;
  background-color: #fff;
  color: $text;
  border: 1px solid $black;
  cursor: pointer;

  &:hover {
    // color: #FFF;
    background-color: $black;
    color: $white;
  }
  
  &--selected {
    background-color: $black;
    color: $white;
  }

  &--selected:hover {
    background-color: #363030;
  }
}