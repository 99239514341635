.scrollViewContainer {
  position: relative;
}

.scrollViewContainer .scrollView {
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollViewContainer .topGrad {
  width: 100%;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,1);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
}

.scrollViewContainer .bottomGrad {
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0);
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );
}

.scrollViewContainer .blueGrad {
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0);
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgb(29, 131, 228) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgb(29, 131, 228)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgb(29, 131, 228) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgb(29, 131, 228) 100%);
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgb(29, 131, 228) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgb(29, 131, 228) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );
}

.overlay {
  pointer-events: none !important;
}

.sidePanelScrollViewContainer {
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}
