@import 'config/colors.scss';

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 99999;
  background: rgba(255,255,255,1);
  background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(29,131,228,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(29,131,228,1)));
  background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(29,131,228,1) 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(29,131,228,1) 100%);
  background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(29,131,228,1) 100%);
  background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(29,131,228,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr= $positive, GradientType=1 );
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  display: block;
  -webkit-animation: shift-rightwards 1s ease-in-out infinite;
  -moz-animation: shift-rightwards 1s ease-in-out infinite;
  -ms-animation: shift-rightwards 1s ease-in-out infinite;
  -o-animation: shift-rightwards 1s ease-in-out infinite;
  animation: shift-rightwards 1s ease-in-out infinite;
  -webkit-animation-delay: .4s;
  -moz-animation-delay: .4s;
  -o-animation-delay: .4s;
  animation-delay: .4s;
}
@-webkit-keyframes shift-rightwards {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  40% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-moz-keyframes shift-rightwards {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  40% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-o-keyframes shift-rightwards {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  40% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes shift-rightwards {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  40% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}
