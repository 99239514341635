
.notificationBellContainer {
  position: relative;
  cursor: pointer;
  /* flex: 0 0 auto; */
  z-index: 1;
}

.notificationBellBackgroundCircle {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: white;
}

.swing {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; }
  20% { -webkit-transform: rotate(20deg); }
  40% { -webkit-transform: rotate(-15deg); }
  60% { -webkit-transform: rotate(10deg); }
  80% { -webkit-transform: rotate(-10deg); }
  100% { -webkit-transform: rotate(0deg); }
}

@keyframes swing {
  20% { -webkit-transform: rotate(20deg); }
  40% { -webkit-transform: rotate(-15deg); }
  60% { -webkit-transform: rotate(10deg); }
  80% { -webkit-transform: rotate(-10deg); }
  100% { -webkit-transform: rotate(0deg); }
}
