@import 'config/colors.scss';
@import 'config/spacing.scss';

.cw-select__container {
  position: relative;
  display: flex;
  overflow: visible;
};

.cw-select__input {
  width: 100%;
  padding: 12px;
  font-size: 0.9rem;
  border: none;
  border-bottom: 1px solid #EAEAEA;
  outline: none;
};

.cw-select__menu {
  position: fixed;
  top: 0;
  left: 0;
  // margin-top: 6px;
  background-color: #FFF;
  border: 1px solid #EAEAEA;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 100000;
}

.cw-select__menu-items {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid #EAEAEA;
}

.cw-select__item {
  box-sizing: border-box;
  width: 100%;
  padding: 9px 12px;
  font-size: 0.9rem;
  border: none;
  border-bottom: 1px solid #FFF;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-Appearance: none;
  -ms-appearance: none;

  background-color: white;
  color: $text;

  &:hover, &:focus, &.cw-select__item--focussed {
    background-color: $background-focussed;
    outline: none;
  }

  &.cw-select__item--selected {
    background-color: $primary;
    color: white;
  }
}

.cw-select__group-header {
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
  font-size: 0.9rem;
  border: none;
  border-bottom: 1px solid $line;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  background-color: white;
  color: #999;
}

.cw-select__label {
  margin-left: 12px;
  overflow: hidden;
  text-overflow: clip;
}

.tagSelectInput {
  border: none;
  outline: none;
}

.tagSelectMenuItemContainer {
  padding: $small $base;
  border-bottom: 1px solid $grey-border;
  background-color: $white;
  font-weight: bold;
  font-size: 14px;
  color: $text;
  cursor: pointer;

  &:hover, &:focus, &.tagSelectMenuItemContainerFocused {
    background-color: $background-focussed;
    outline: none;
  }

  p:nth-child(2) {
    font-weight: 200;
    margin-top: -5px;
    font-size: 13px;
  }

}

.tagSelectButtonContainer {
  margin-right: $base;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 3px;
}

.shift-form-select {

  &__container {
    width: 100%;
    height: 30px;
    border: 1px solid hsl(0,0%,80%);
    border-radius: 2px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &:focus, &--open {
      border: 1px solid #005E86;
    }

    &--invalid {
      border: 1px solid $error;
    }
  }

  &__dropdown-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;


    &:hover {
      cursor: default;
    }
  }

  &__divider {
    position: absolute;
    top: 2;
    left: 0;
    width: 1px;
    height: 14px;
    background-color: hsl(0,0%,80%);;
  }

  &__chevron {
    color: hsl(0,0%,80%);;

    &:hover {
      color: hsl(0,0%,60%);;
    }
  }

  &__value-text {
    font-size: 0.9rem;
    padding-left: 8px;
    
    &--normal {
      color: $text;
    }

    &--placeholder {
      color: #808080;
    }
  }
}