@import 'config/colors.scss';

.datePicker .SingleDatePicker {
  border: none;
  background: transparent;
  display: inline-block;
}

.datePicker .SingleDatePicker .CalendarDay__selected {
  background: $positive-light;
  border-color: $positive;
  color: white;
}

.datePicker .SingleDatePickerInput {
  width: 120px;
  text-align: center;
  /*width: 90px !important;*/
  background-color: transparent;
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.datePicker .SingleDatePickerInput .DateInput_input {
  height: 30px;
  padding: 0;
}

.datePicker .SingleDatePickerInput .DateInput_input__focused {
  background: $positive;
  color: white !important;
  height: 30px;
  padding: 0;
  border-radius: 3px;
  margin: 0 !important;
}

.datePicker .SingleDatePickerInput .DateInput_input__focused::placeholder {
  color: white !important;
}

.datePicker .DateRangePickerInput {
  border: none !important;
  background: transparent;
}

.datePicker .DateInput_fangStroke {
  display: none;
}

.datePicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.remove_account_select {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .DateInput_input {
    font-size: 1rem;
  }
}

.DateInput_input::placeholder {
  font-style: normal !important;
}


.SingleDatePickerInput .SingleDatePickerInput_1 .SingleDatePickerInput__withBorder .SingleDatePickerInput__withBorder_2 {
  width: 100%;
}

.DateInput .DateInput_1 {
  width: 97%;
}

.DateInput {
  width: 97%;
}

.DateInput_input {
  padding: 6px 6px;
  font-size: 15px;
  text-decoration: none;
  width: 100%;
  font-family: 'Oxygen', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: hsl(0,0%,20%);
  padding-left: 12px;
  height: 23px;
}

.DateInput_input:hover {
  cursor: default;
}

.SingleDatePickerInput__withBorder {
  border-radius: 4px;
  border: none;
}

.SingleDatePicker:first-child {
  width: 100%;
}

.SingleDatePicker_1:first-child {
  width: 100%;
}


.SingleDatePickerInput__disabled {
  border: 1px solid hsl(0,0%,80%) !important;
}

.SingleDatePickerInput {
  width: 100%;
  border: 1px solid hsl(0,0%,80%) !important;
}

.SingleDatePickerInput:focus {
  box-shadow: none;
}

.SingleDatePickerInput__withBorder:focus {
  box-shadow: none;
  width: 99.8%;
}

.DateInput_input .DateInput_input_1 .DateInput_input__disabled .DateInput_input__disabled_2::placeholder {
  font-style: normal;
  font-family: sans-serif;
}


.DateInput_input::placeholder {
  font-style: normal;
}

.DateInput_input__focused {
  width: 100%;
  box-shadow: none;
  border-radius: 4px;
}

.DateInput::placeholder {
  font-style: normal;
}

.DateInput_input .DateInput_input_1 .DateInput_input__focused .DateInput_input__focused_2 {
  width: 625px;
}

// .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
//   background-color: #2684FF;
// }


.CalendarDay {
  font-size: 0.9rem !important;
  color: $text !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: $primary !important;
  border: none !important;
  color: #fff !important;
}

.CalendarMonth_caption {
  color: $text;
}

.SingleDatePicker {
  width: 100%;
}

.DateInput.DateInput_1 { 
  width: 96%;
}
