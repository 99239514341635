@import 'config/colors.scss';

.loading-container {
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ratings-tab-container {
  display: flex;
  flex: 2;
  flex-direction: row;
  padding: 12px;
  height: 100%;
}
.ratings-section-header {
  color: #666;
  font-weight: normal;
  font-size: 24px;
}

.ratings-list-item-header {
  color: '#666';
  font-weight: normal;
}

.rating-summary-container {
  flex: 1;
  margin-right: 24px;
}

@media screen and (max-width: 1200px)  {

  .ratings-tab-container {
    flex-direction: column;
    overflow: scroll;
  }

  .rating-summary-container {
    margin-right: 0;
    margin-bottom: 12px;
  }
}

.rating-category {
  display: flex;
  flex-direction: row;
  color: #005E86;
  margin-bottom: 12px;
  align-items: flex-start;
  margin-right: 12px;
  align-items: center;
}

.rating-category-text {
  width: 55%;
  line-height: 1.2;
  
  color: #666;
  font-size: 12px;

  strong {
    color: #333;
    font-size: 15px;
  }
}

.rating-category-ratings {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  color: $text;
}

.rating-detail-container {
  flex: 1;
  height: 100%;
}

.title-break {
  border-width: 0;
  height: 1px;
  background-color: #979797;
  width: 100%;
}

.rating-list-item {
  border-bottom: 1px solid #979797;
  overflow: hidden;
}

.breakdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $text;
  font-size: 12px;
  width: 90%;
}

.rating-list-item .ratings-list-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
}

.rating-list-item .ratings-list-item-title p {
  color: $text;
  font-size: 15px;
}

.rating-list-item .comment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: $text;
  font-size: 12px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rating-list-item .comment div {
  width: 90%;
}

.rating-list-item .rating {
  font-size: 24px;
  line-height: 28px;
}

.ratings-tab-scroll-view-container {
  height: 100%;
}
