@import 'config/colors.scss';

.shiftCardCandidateLink {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &--disabled {
    cursor: auto;

    &:hover {
      text-decoration: none;
    }
  }
}

.shiftCard {

  &__marching-ants {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #333;
    stroke-width: 2px;
    vector-effect: non-scaling-stroke;
    stroke-dasharray: 4px;
    stroke-dashoffset: 8px;
    animation: marching-ants-animation 0.4s linear infinite;
  }
}

.shiftCard--is-draft {
  .shiftCardCandidateLink {
    color: #FFF;
  }
}


.shiftCardOverlay {
  display: none;
  &.shiftCardOverlayAlwaysShow { display: block }
}
.shiftCard:hover .shiftCardOverlay {
  display: block;
}

@keyframes marching-ants-animation {
  to {
    stroke-dashoffset: 0;
  }
}