@import 'config/colors.scss';

.small-message-button {
  display: flex;
  justify-content: center;
  color: $positive;
  height: 24px;

  &:hover {
    opacity: 0.8;
  }
}