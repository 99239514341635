.addFilterContainer {
  border: 1px dotted rgb(0, 94, 134);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(0, 94, 134);
  font-size: 0.8em;
  padding: 0 6px 0 6px;
  margin: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
}

.addFilterButtonContainer {
  margin-left: 12px;
  margin-right: 12px;
  display: inline-block;
  padding: 0 1px 2px 1px;
  border-radius: 50%;
  color: #A4C2F4;
  cursor: pointer;
  transition: all .3s ease;
}

.addFilterButtonContainer * {
  cursor: pointer;
}

.addFilterButtonContainer:hover {
  background-color: rgb(0, 94, 134);
  color: white;
}

.filterBarButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
