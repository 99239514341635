@import 'config/colors.scss';

.MonthCalendarEventComponentContainer {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.applicant-icons {
  margin-left: 5px;
  text-align: left;
  line-height: 1;
  height: 19px;
}


.applicant-icon {
  display: inline-block;
  padding-right: 5px;
  margin-top: 3px;
  font-size: 0.9rem;
}

.cal-counts-bar,
.cal-counts-bar-key {
    text-align: center;
    display: block;
}

.cal-counts-bar .shiftCalendarPills {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
}

.calendarKeyContainer > .cal-counts-bar {
  width: 100px;
}

.shiftCalendarPills.cal-counts-bar,
.shiftCalendarPills.cal-counts-bar-key {
  width: 220px;
  font-size: 1rem;
  line-height: auto;
  // margin-left: 5px;
  // margin-right: 5px;
  display: flex;
}

.shiftCalendarPills {
  margin-left: 18px;
  display: inline-block;
  position: relative;
  color: $background-light-4;
}

.shiftCalendarPills > .shiftCalendarPills > .shiftCalendarCount {
  line-height: normal;
}

.shiftCalendarPills--agency .shiftCalendarCount--is-filled {
  color: white;
  background-color: $agency-filled;
} 

.shiftCalendarPills--agency .shiftCalendarCount--un-filled {
  color: white;
  background-color: $agency-unfilled;
} 

.shiftCalendarPills--bank .shiftCalendarCount--is-filled {
  color: white;
  background-color: $bank-filled;
}

.shiftCalendarPills--bank .shiftCalendarCount--un-filled {
  color: white;
  background-color: $bank-unfilled;
}

.shiftCalenderPillLabel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: white;
  font-size: 0.9rem;
}

.shiftCalendarCount,
.shiftCalendarCountKey {  
  height: 24px;
  line-height: 18px;
  flex-grow: 1;
  font-size: 0.9rem;
  // padding: 3px;
  // border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftCalendarCountKey { font-size: 0.9rem; }

.shiftCalendarCount.shiftCalendarCount--is-filled.shiftCalendarCount--zero-count {
  border: none;
  min-width: 0;
  background-color: transparent;
}

.shiftCalendarCount.shiftCalendarCount--un-filled.shiftCalendarCount--zero-count {
  border: none;
  min-width: 0;
  background-color: transparent;
}

.calendarKeyContainer {
  width: 100%;
  border-top: 1px solid rgba(164, 194, 244, 0.2);
  // padding-top: 5px;
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: $background-light-6;
}

.keyText {
  color: $text;
  font-weight: 600;
  margin-right: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
}