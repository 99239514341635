@import 'config/colors.scss';

.form-input {
  outline: none;
 
  &:active,
  &:focus {
    outline: none;
    border: 1px solid $primary;
  }
}