.bankSearchBoxInput::-ms-clear {
    display: none;
}
::-webkit-input-placeholder {
   font-style: italic !important;
}
:-ms-input-placeholder {
   font-style: italic !important;
   color: #868989 !important;
   padding-top: 4px !important;
}
