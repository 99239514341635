.updateMessageBar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 55px;
  background-color: #d8a285;
  z-index: 100000000;
  box-shadow: 0 5px 8px rgba(50,50,93,.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeFromTop 0.2s;
  animation-timing-function: cubic-bezier(.59,1.54,1,.797);
  animation-delay: 1s;
  animation-fill-mode: backwards;
  outline: none;
}

.updateMessageBar:active,
.updateMessageBar:focus
.updateCompleteMessageBar:active,
.updateCompleteMessageBar:focus,
.messageFadeOut:active,
.messageFadeOut:focus {
  outline: none;
  border: none;
}

.updateCompleteMessageBar { 
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 55px;
  background-color: #94b8ae;
  z-index: 100000000;
  box-shadow: 0 5px 8px rgba(50,50,93,.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeFromTop 0.2s;
  animation-timing-function: cubic-bezier(.59,1.54,1,.797);
  animation-delay: 1s;
  animation-fill-mode: backwards;
  outline: none
}

.messageFadeOut {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #94b8ae;
  z-index: 100000000;
  box-shadow: 0 5px 8px rgba(50,50,93,.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: disappear 1s;
  animation-timing-function: cubic-bezier(.59,1.54,1,.797);
  height: 0px;
  opacity: 0;
}

.restartLink {
  text-decoration: underline;
}

.restartLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.hideMessageBar {
  top: -30;
}

@keyframes disappear {
  0% {
    transform: translateY(0);
    opacity: 1;
    height: 55px;
  }
  100% {
    transform: translateY(-100px);
    height: 0px;
  }
}

@keyframes fadeFromTop {
  0% {
    transform: translateY(-55px);
    opacity: .8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    height: 55px;
  }
}
