@import 'config/colors.scss';

.acceptance-documents {

  &__link {
    cursor: pointer;
    line-height: 1.4;
    color: $text;
    font-size: 0.9rem;
  }

  &__link:hover {
    text-decoration: underline;
  }
}