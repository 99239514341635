@import 'config/colors.scss';

.booked-candidate-link {
  color: #999;
}

.booked-candidate-link--filled-by-bank {
  color: $text;
  font-weight: 500;
}

.booked-candidate-link--filled-by-agency {
  color: $red;
  font-weight: 500;
}

.booked-candidate-link:hover {
  text-decoration: underline;
}

.booked-candidate-link:active,
.booked-candidate-link:focus {
  outline: none;
  border: none;
}

// .data-table-row:nth-of-type(even).shifts-list__draft-row {
//   background-color: lighten($invalid, 20%);
// }

// .data-table-row:nth-of-type(odd).shifts-list__draft-row {
//   background-color: lighten($background-light-3, 20%);
// }

// .data-table-row:nth-of-type(even).shifts-list__draft-row:hover,
// .data-table-row:nth-of-type(odd).shifts-list__draft-row:hover,
// .data-table-row.shifts-list__draft-row.shifts-list__selected-row {
//   background-color: lighten($invalid, 20%);
// }


.data-table-row:nth-of-type(even).shifts-list__draft-row-invalid,
.data-table-row:nth-of-type(odd).shifts-list__draft-row-invalid {
  background-color: #f6b26b;
}

.data-table-row.shifts-list__selected-row,
.data-table-row.shifts-list__selected-row:hover {
  background-color: #EAEAEA;
}
