@import 'config/colors.scss';
@import 'config/spacing.scss';

table, thead, tbody, tr, th {
  border: '1px solid black';
}

.scroll-content {
  max-height: 250px;
}

.timesheet-table-head tr th {
  background-color: #f7f7f7;
}

.timesheet-table-body tr th:first-child {
  background-color: #f7f7f7;
}

.timesheet-table-body tr th:not(:first-child) {
  font-weight: normal;
}

.tsa-react-select__control {
  margin-bottom: 6px;
  background-color: white !important;
  color: #999999 !important;
  /* max-height: 32px !important;
  min-height: 32px !important; */
  min-width: 350px;
  font-weight: normal;
}

.tsa-react-select__value-container {
  cursor: pointer;
  /* height: 10px !important;
  line-height: 32px; */
}

.tsa-react-select__single-value {
  width: 100%;
}

.tsa-react-select__option {
  color: #999999 !important;
  font-weight: normal;
  cursor: pointer !important;
}

.tsa-react-select__option--is-selected {
  background-color: $positive !important;
  color: #fff !important;
}

.tsa-react-select__control--is-focused {
  border: none !important;
}

.tsr-react-select__control {
  background-color: white !important;
  color: #999999 !important;
  /* max-height: 32px !important;
  min-height: 32px !important; */
  min-width: 350px;
  font-weight: normal;
}

.tsr-react-select__value-container {
  cursor: pointer;
  /* height: 10px !important;
  line-height: 32px; */
}

.tsr-react-select__single-value {
  width: 100%;
}

.tsr-react-select__option {
  color: #999999 !important;
  font-weight: normal;
  cursor: pointer !important;
}

.tsr-react-select__option--is-selected {
  background-color: $positive !important;
  color: #fff !important;
}

.tsr-react-select__control--is-focused {
  border: 1px solid #e06666 !important;
  box-shadow: 0 0 0 1px #e06666 !important;
}

.timesheet {
  &__header { 
    color: $text;
    font-size: 1.3rem;
    font-weight: 600;
    padding: 12px 0px;
    // margin-bottom: 6px;
  };
}

.timesheet-submission {
  padding: 12px;
  padding-bottom: 6px;

  &__info-text {
    color: $text;
    font-size: 0.9rem;
  }

  &__buttons {
    margin-top: 12px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  &__disclaimer-heading {
    color: $black;
    font-size: 0.9rem;
    line-height: 1.3;
    margin: 6px 0px;
    margin-top: 12px;
    font-weight: 500;
  }

  &__disclaimer-message {
    color: $black;
    font-size: 0.9rem;
    line-height: 1.4;
    font-style: italic;
  }
}

.submission-table {
  display: flex;
  flex-direction: column;
  color: $text;
  font-size: 16px;

  &__header {
    border-bottom-width: 1px;
    border-bottom-color: $accent;
    border-bottom-style: solid;
    margin-bottom: $small;
  }

  &__header,
  &__row {
    display: flex;
  }

  &__header-item,
  &__row-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 25%;
  }

  &__header-text { 
    color: $text;
    font-weight: 600;
    line-height: 1.45;
    font-size: 0.9rem;
  };

  &__row-text {
    line-height: 1.45;
    color: $text;
    font-weight: 400;
    font-size: 0.9rem;
  }

  &__row-text--red {
    color: $red;
    font-weight: 500;
    font-size: 0.9rem;
  };
}

.timesheet-permission-message {
  color: $red;
  font-size: 0.9rem;
  font-weight: 600;
}

.total_hours_label {
  color: $gray;
  font-weight: 500;
  line-height: 1.45;
  width: 180px;
}

.total_hours_value {
  line-height: 1.45;
  color: $text;
  font-weight: 400;
}

.candidate-rating {
  padding: 12px;
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  color: $text;

  &__footer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
  }

  &__submitted-by-text {
    margin-top: 12px;
    font-size: 14px;
  }
};

.rating-table {
  
  &__rating {
    flex-basis: 33%;
  }

  &__row {
    display: flex;
    padding: 6px;
    align-items: center;
    position: relative;
  }
  
  
  &__category {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    font-size: 14px;
    line-height: 1.2;

    &--name { 
      font-weight: 600;
      color: $text;
    };

    &--description { font-size: 0.9rem };
  }

  &__description {
    font-size: 0.9rem;
    flex-basis: 33%;
    line-height: 1.2;
    flex-wrap: wrap;
  }
}

.timesheet-details {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__column:first-child { 
    border-right-width: 1px;
    border-right-color: $accent;
    border-right-style: solid;
  };

  &__row {
    display: flex;
    overflow: hidden;
    flex: 1;
  };
}

.column-left {
  flex-basis: 60%;
  overflow-y: auto;
};

.column-right { flex-basis: 40% };

.timesheet-details * {
  box-sizing: border-box;
}

.timesheet-header {
  background-color: $background-light-6;
  color: $text;
  padding: 12px;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.4;

  &__close-button:hover { cursor: pointer; };

  &__bar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
  
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: -4px;
    margin-bottom: 12px;
  }

  &__text {
    color: $text;
  }

  &__details { 
    display: flex;
  
  };

  &__column {
    display: flex;
     width: 33%
  }

  &__column--left {
    display: flex;
    flex-direction: column;
    width: 30%;
    font-weight: 600;
  }
  
  &__column--right {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  &__row { display: flex };
}

.timesheet-messages {

  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__header { padding: 12px };

  &__scrollview {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px;
    height: 100%;
  }
}
.candidate-review {
  margin-top: 12px;

  &__info-text {
    font-size: 0.9rem;
    margin-bottom: 6px;
  }
}