$brand: #005E86;
$brand-tertiary: #2C7796;
$positive: #005E86;
$positive-light: #45A0A8;
$accent: #EEE;
$negative: #B7B7B7;
$accent: #e6e6e6;
$text: #000000;
$light-text: #666;
$disabled: #e6e6e6;
$title: #A4C2F4;
$hover-grey: #f7f5f5;
$understated: #b7b7b7;
$more-understated: #{"hsla(0, 0%, 79%, 1)"};
$border: #eaeaea;
$text-input: #999999;
$error: #e06666;
$line-color: #f0f0f0;
$green: #33cd5f;
$create: #bbd685;
$create-alt: #cee89a;
$amber: #f6b26b;
$red: #D8A285;
$white: #ffffff;
$black: #000000;
$charcoal: #{"hsla(0, 0%, 20%, 1)"};
$warning: #e5bb67;
$background-color: #{"rgb(241, 245, 249)"};
$buttonText-color: #ffffff;
$side-menu-background-color: #ffffff;
$side-menu-textColor: #999999;
$header-bar-background-color: #ffffff;
$header-bar-text-color: #8a8a8a;
$coin-color: #{"hsla(55, 100%, 50%, 1)"};
$bright-red: #ff6961; // #{"hsla(6, 80%, 53%, 1)"}
$deep-maroon: #{"hsla(6, 100%, 20%, 1)"};
$gray: #{"hsla(60, 1%, 33%, 1)"};
$grey-background: #f7f7f7;
$grey-border: #EAEAEA;
$nav-bar-white: #{"hsla(48, 100%, 99%, 1)"};
$pinkish-white: #{"hsla(0, 50%, 99%, 1)"};
$agency: #e06666;
$unfilled: #f4a142;
$redButton: #EA9999;
$blueBase: #{"hsla(209, 79%, 50%, 1)"};
$blue-light: #{"hsla(209, 79%, 70%, 1)"};
$blue-very-light: #{"hsla(209, 79%, 85%, 1)"};
$blue-lightest: #{"hsla(210, 40%, 96%, 1)"};
$pastel-green: #9cdbae;
$tab-blue: #eaf6ff;
$base-blue: #{"hsla(209, 79%, 50%, 1)"};
$light-blue: #{"hsla(209, 79%, 70%, 1)"};
$very-light: #{"hsla(209, 79%, 85%, 1)"};
$ultra-light: #{"hsla(210, 40%, 96%, 1)"};
$header-blue: #e1f0ff;
$charcoal: #{"hsla(0, 0%, 20%, 1)"};
$medium-gray: #{"hsla(0, 0%, 40%, 1)"};
$medium-light-gray: #{"hsla(0, 0%, 60%, 1)"};
$light-gray: #{"hsla(0, 0%, 72%, 1)"};
$very-light-gray: #{"hsla(0, 0%, 79%, 1)"};
$ultra-light-gray: #{"hsla(0, 0%, 86%, 1)"};
$transparent: #{"hsla(0, 0%, 0%, 0)"};
$sidebar-link:  #dae5f9;
$sidebar-background-rgb: #{"rgb(2, 52, 101)"};
$sidebar-background-lighter: #005E86;
$teal-ultra-light: #a0d1db;
$teal-ultra-light-gray: #cae7ed;
$teal-ultra-light-gray-green: #b2e1eb;
$teal-ultra-light-gray-dark: #b6cfd4;
$rating-yellow: #FDCC0D;
$primary: #D8A285;
$secondary: #94b8ae;
$tertiary: #A3B9C3;
$line: #0000001a;
$background: #FAF8F3;
$background-focussed: #c5c0bd1f;
$active: #94b8ae;
$pending: #e5bb67;
$error: #d8a285;
$invalid: #c7bbb6; 
$background-light-1: #FAF8F3;
$background-light-2: #f0e8dd;
$background-light-3: #c7bbb6;
$background-light-4: #C7BBB6;
$background-light-5: #F7F6F4;
$background-light-6: #F2EEE8;
$bank-filled: #879AA3;
$bank-unfilled: #A3B9C3;
$agency-filled: #A9A19E;
$agency-unfilled: #C7BBB6;
