.section-separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.section-separator::before, .section-separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #CCC;
}
.section-separator::before {
    margin-right: .25em;
}
.section-separator::after {
    margin-left: .25em;
}