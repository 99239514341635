@import 'config/colors.scss';

.datePicker {
  z-index: 10;
}

a.dateFilterOption {
  font-family: 'Oxygen', sans-serif;
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  color: $primary;
  margin-right: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

a.dateFilterOption:hover,
a.dateFilterOption.dateFilterOptionSelected {
  color: $primary;
  font-weight: bold;
}

.datePicker .DateRangePickerInput_arrow_svg {
  position: relative;
  left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  fill: $black;
  height: 16px;
}

.datePicker .DateInput,
.datePicker .DateInput_input {
  color: $text !important;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  vertical-align: middle !important;
  white-space: nowrap;
  word-break: keep-all;
  font-family: 'Oxygen', sans-serif;
  /*padding: 0;*/
}

.datePicker .DayPickerKeyboardShortcuts_show__bottom-right {
  display: none;
}

.datePicker .transition-container{
  height: 341px !important;
}

.datePicker .DayPicker__horizontal {
  // box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 0 0 1px rgba(0,0,0,.07);
  /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);*/
  border-radius: 6px;
}

.datePicker .DateRangePicker {
  padding-left: 10px;
  width: 250px;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  opacity: 0;
  animation: loaded 0.25s ease forwards;
}

.datePicker .DateInput_fangStroke {
  display: none;
}

.datePicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

@keyframes loaded {
  100% {
    opacity: 1;
    transform: none;
  }
}

.datePicker .DateInput {
  width: 90px;
  background-color: transparent;
  padding: 0;
}

.datePicker .DateInput_input__focused {
  // background: $background-light-6;
  color: black !important;
  height: 30px;
  margin-left: 6px;
  padding: 0;
  // border-radius: 3px;
  border-bottom: 1px solid $primary;
  box-shadow: none;
  font-size: 0.9rem;
  font-weight: 600;
}

.datePicker .DateRangePickerInput {
  border: none !important;
  background: transparent;
}

.datePicker .DateRangePicker .CalendarDay__selected_span,
.datePicker .DateRangePicker .CalendarDay__selected {
  background: $primary;
  border-color: $primary;
  color: white;
}
