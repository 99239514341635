@import 'config/colors.scss';

.sideMenu {
  box-sizing: border-box;
  max-height: calc(100vh - 200px);
  padding-bottom: 24px;
  margin-top: 80px;
}

a.sideMenuItem,
a.sideMenuItem:link,
a.sideMenuItem:visited,
a.sideMenuItem:active,
div.sideMenuArrow,
div.personIconContainer {
  display: flex;
  align-items: center;
  position: relative;
  color: $black;
  text-decoration: none;
  padding: 12px 18px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

a.red-pad {
  padding-left: 22px;
  &:link, &:active, &:visited { padding-left: 22px; }
}

a.inc-pad {
  padding-left: 27px;
  &:link, &:active, &:visited { padding-left: 27px; }
}


div.personIconContainer {
  position: relative;
  padding-left: 20px;
}

div.sideMenuArrow {
  color: #fff ;
}

.nav-icon {
  padding-right: 0;
  color: $primary
}

.nav-noti {
  padding-right: 13px;
  margin-top: -3px;
  margin-left: 1px;
}

a.sideMenuItem.sideMenuInner {
  font-size: .9rem;
  line-height: 1.3;
  padding: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

a.sideMenuItem:hover,
a.sideMenuItemSelected:link,
a.sideMenuItemSelected:visited,
a.sideMenuItemSelected:active {
  background-color: $background-focussed;
}

div.sideMenuArrow {
  padding: 12px 24px;
  text-align: right;
}

.sideMenuBadgeCount {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  min-width: 14px;
  padding: 0 2px;
  line-height: 1;
  background-color: $text;
  color: $white;
  font-weight: 500;
  border-radius: 14px;
  top: -4px;
  left: 18px;
  z-index: 1;
  font-size: .9rem;
}