@import 'config/colors.scss';

.button {
  box-sizing: border-box;
  display: inline-block;
  padding: 9px 18px;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
  color: white;
  background-color: $brand;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.button.disabled {
  opacity: 0.5;
  cursor: default;
}

.button.button--small {
  font-size: 0.8rem;
  padding: 6px 12px;
  &.button--outline { padding: 5px 11px };
}
.button.button-medium {
  /* nothing - this is the default style */
  padding: 9px 18px;
  &.button--outline { padding: 8px 17px };
}
.button.button--large {
  padding: 12px 24px;
  &.button--outline { padding: 11px 23px };
}
.button.button--huge {
  padding: 18px 36px;
  &.button--outline { padding: 11px 23px };
}

.button.button--full-width { width: 100%; }
.button.button--half-width { width: 50%; }

.button.button--positive { background-color: $brand; }
.button.button--negative { background-color: $negative; }
.button.button--warning  { background-color: $warning; }
.button.button--danger   {
  background-color: $red;
  // &.disabled {
  //   opacity: 0.5;
  // }
}
.button.button--create   { background-color: $create; }

.button.button--white   {
  background-color: $white;
  color: $black;
  border-color: $line;
  border-width: 1px;
  border-style: solid;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.button.button--white.button--outline   {
  background-color: $white;
  color: $black;
  border-color: $black;
  border-width: 0.25px;
  border-style: solid;
  &:hover {
    background-color: $black;
    color: $white;
  }

  // &.disabled {
  //   opacity: 0.5;
  // }

  &.disabled:hover {
    background-color: $white;
    color: $black;
  }
}

.button.button--black   {
  background-color: $black;
  color: $white;
  border-color: $line;
  border-width: 1px;
  border-style: solid;
  &:hover {
    background-color: #363030;
  }

  &.disabled:hover {
    background-color: $black;
  }
}

.button.button--danger   {
  background-color: $red;
  color: $white;
  border-color: $red;
  border-width: 1px;
  border-style: solid;
  &:hover {
    background-color: $white;
    color: $red;
  }

  // &.disabled:hover {
  //   background-color: $black;
  // }
}

.button.button--positive.button--outline   {
  color:$brand;
  background-color: #fff;
  border-color: $brand;
  border-width: 1px;
  border-style: solid;
}
.button.button--negative.button--outline   {
  color:$negative;
  background-color: #fff;
  border-color: $negative;
  border-width: 1px;
  border-style: solid;
}
.button.button--warning.button--outline   {
  color:$warning;
  background-color: #fff;
  border-color: $warning;
  border-width: 1px;
  border-style: solid;
}
.button.button--danger.button--outline   {
  color:$red;
  background-color: #fff;
  border-color: $red;
  border-width: 1px;
  border-style: solid;
}
.button.button--create.button--outline   {
  color: darken($create, 20%);
  background-color: #fff;
  border-color: darken($create, 20%);
  border-width: 1px;
  border-style: solid;
}