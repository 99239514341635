.bankManagement {
    box-sizing: border-box;
    position: relative;
    height: 100%;
}

.bankManagement * {
    box-sizing: border-box;
}

.bank-compliance-status-column {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bank-header-need-review-count {
  margin-right: 12px;
  color: #005E86;
  font-weight: 500;
  cursor: pointer;
}

.bank-header-button-container {
  position: relative;
  /* bottom: 5px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
