@import 'config/colors.scss';
@import 'config/spacing.scss';

.compliance-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
}

.compliance-tab-main {
  display: flex;
  border-top: 1px solid $accent;
  flex: 1;
  overflow-y: hidden;
}

.compliance-items {
  flex-basis: 30%;
  border-right: 1px solid $accent;
  flex-direction: column;
  padding-top: $small;
  overflow-y: auto;
}

.compliance-item {
  flex-basis: 70%;
  padding: 6px 18px 6px 18px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  &__header {
    color: $text;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 12px 0px 12px 0px;
  }

  &__text {
    color: $text;
    font-size: 0.9rem;
  }
}

.evidence-category {
  &__header {
    color: $text;
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 0px 12px 0px;
  }
}

.compliance-list-item {
  height: 24px;
  padding: 24px 12px;
  border-bottom: 1px solid $accent;
  display: flex;
  align-items: center;
  line-height: 1;

  &:hover {
    cursor: pointer;
    background-color: $hover-grey;
  }

  &__status-circle {
    margin-right: $base;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-basis: 70%;

    & p {
      color: $text;
    }
  }

  &__summary {
    font-size: 0.9rem;
  }

  &:last-of-type {
    border: none;
  }

  &--selected {
    background-color: $hover-grey;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.compliance-item-category {
  margin-bottom: $base;
  padding: 12px 0px;
  
  &__title {
    color: $text;
    font-size: 1.2rem;
    padding: 3px 0px 3px 0px;
    padding-left: $base;
    font-weight: 500;

    &--first-in-list {
      border-top: none;
    }
  }
}

.compliance-items-list {
  display: flex;
  flex-direction: column;
}

.status-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: $red;
}

.certificates-container {
  overflow-y: auto;
  height: 100%;
}

.submission {
  display: flex;
  flex-direction: column;
  border: 1px solid $accent;
  margin-bottom: $large;
}

.submission-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $accent;
  padding: 6px 12px 6px 12px;

}

.submission-header-title {

  line-height: 1.4;
  display: flex;

  &__title-text {
    color: $text;
    font-size: 1rem;
    font-weight: 500;
    margin-right: 12px;
  }

  &__submission-status {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
}

.submission-header-sub-title {
  &__date {
    color: $light-text;
    font-size: 0.8rem;
    line-height: 1
  }
}

.submission-header-top {
  display: flex;
  justify-content: space-between;
  &__details {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  &__buttons {
    display: flex;
    align-items: center;
  }
}

.submission-header-bottom {
  display: flex;
  justify-content: flex-end;

  &__permission-message {
    color: $red;
  }
}

.compliance-submission {
  display: flex;
  flex-direction: column;
  padding: $base;

  &__section-header {
    color: $text;
    font-size: 18px;
    font-weight: 500;
  }
}

.compliance-submission-section {

  margin: 12px 0px;

  &__header {
    color: $text;
    font-size: 1rem;
    font-weight: 600;
  }
}

.summary-details {
  display: flex;
  flex-direction: column;

  &--row {
    flex-direction: row;
  }

  &__column-left {
    display: flex;
    flex-direction: column;
    flex-basis: 36%;
    justify-content: center;
  }

  &__column-right {
    display: flex;
    flex-direction: column;
    flex-basis: 64%;
  }
}

.certificate-submission {
  display: flex;
  padding: $base;

  &__section-header {
    color: $text;
    font-size: 18px;
    font-weight: 500;
  }

  &__column-left {
    display: flex;
    flex-direction: column;
    flex-basis: 36%;
    justify-content: center;
  }

  &__column-right {
    display: flex;
    flex-direction: column;
    flex-basis: 64%;
  }

  &__group-title {
    margin-top: 12px;
  }

  &__label {
    color: #999;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.45;
    flex: 0 0 36%;
  }

  &__value-container {
    flex-basis: 64%;
    line-height: 1;
  }

  &__value {
    color: $text;
    line-height: 1.45;
    font-size: 1rem;
  }

  &__value-explanation {
    color: $text;
    line-height: 1.3;
    font-size: 13px;
    margin-top: -2px;
  }
}

.submission-evidence {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 240px;
  position: relative;
  cursor: pointer;
  background-color: $understated;
}

.submission-magnify {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.6);
}

.submission-evidence:hover .submission-magnify {
  visibility: visible;
}

.submission-image {
  max-height: 200px;
  max-width: 200px;
}

.certificate-reject-reason {
  display: flex;
  margin-bottom: $base;
}

.certificate-field {
  display: flex;
  padding: 6px 0px;
}

.submission-bubble-container {
  overflow-y: auto;
}

.submission-full-size {
  flex: 1;
  display: flex;
  overflow-y: auto;
  justify-content: center;
  background-color: $negative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &__image {
    max-width: 100%;
    max-height: 100%;
  }
}
