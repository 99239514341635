@import 'config/colors.scss';

.candidate-rgs-tab {
  border-top: 1px solid #CCC;
  height: 100%;
}

.candidate-rgs-tab, .candidate-rgs-tab * {
  box-sizing: border-box;
}

.table-header-rotated {
  border-collapse: collapse;

  // .no-csstransforms & th {
  //   padding: 5px 10px;
  // }

  td {
    text-align: center;
    padding: 12px;
    border: 1px solid #ccc;
    line-height: 0;
    width: 48px;
    height: 48px;
  }

  th {
    font-weight: normal;
    color: #666;
  }

  th.rotate {
    height: 140px;
    white-space: nowrap;
    // Firefox needs the extra DIV for some reason, otherwise the text disappears if you rotate 
    > div {
      transform: translate(38px, 54px) rotate(315deg);
      width: 30px;
    }

    > div > span {
      display: inline-block;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #ccc;
      text-align: left;
      padding-left: 6px;
      color: $text;
      font-size: 0.9rem;
      font-weight: 500;
      // padding: 5px 10px;
    }
  }

  th.row-header {
    text-align: left;
    font-weight: 500;
    padding: 0 12px;
    width: 300px;
    color: $text;
    font-size: 0.9rem;

    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    &:first-of-type { border-top: 1px solid #ccc; }
  }
}