@import 'config/colors.scss';

.reportExports {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.reportExports .reportExport {
  border-radius: 2px;
  padding: 12px 16px 12px 16px;
  color: $text;
  background-color: $background-light-5;
  border: 1px solid $line;
  margin-right: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reportExportWrapper {
  min-height: 1500px;
  width: 95%;
}

.reportControls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: inherit;
}

.reportExports .reportExport h2 {
  color: $text;
  font-size: 1.3rem;
  font-weight: 600;
}

.reportExports .reportExport p {
  font-size: 0.9rem;
  margin-top: 12px;
}

.reportExports .reportExport .reportButtons {
  margin-top: 14px;
}

.reportExports .reportProgress .progressWrapper {
  height: 2px;
  display: flex;
  margin-top: 15px;
  border-radius: 4px;
}

.reportExports .reportProgress .progressWrapper .progress {
  height: 2px;
  background-color: $primary;
  border-radius: 4px;
}

.picker {
  margin-top: 10px;
}

.reportDatePicker {
  border-radius: 4px;
  border: 1px solid rgba($positive, 0.1);
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

a.dateFilterOption {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: $text;
  margin-right: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-weight: 500;
}

a.dateFilterOption:hover,
a.dateFilterOption.dateFilterOptionSelected {
  color: $primary !important;
  font-weight: 600;
}

.reportDatePicker .DateRangePickerInput_arrow_svg {
  position: relative;
  left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  fill: $black;
  height: 16px;
}

.reportDatePicker .DateInput,
.reportDatePicker .DateInput_input {
  color: $text;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  text-decoration: none;
  vertical-align: middle !important;
  white-space: nowrap;
  word-break: keep-all;
  /*padding: 0;*/
  background-color: transparent;
}

.reportDatePicker .DayPickerKeyboardShortcuts_show__bottom-right {
  display: none;
}

.reportDatePicker .transition-container{
  height: 341px;
}

.reportDatePicker .DayPicker__horizontal {
  box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 0 0 1px rgba(0,0,0,.07);
  /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);*/
  border-radius: 6px;
}

.reportDatePicker .DateRangePicker {
  padding-left: 10px;
  width: 250px;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  opacity: 0;
  animation: loaded 0.25s ease forwards;
}

.reportDatePicker .DateInput_fang, .reportDatePicker .DateInput_fangStroke {
  display: none;
}

.reportDatePicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

@keyframes loaded {
  100% {
    opacity: 1;
    transform: none;
  }
}

.reportDatePicker .DateInput {
  width: 90px;
  background-color: transparent;
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.reportDatePicker .DateInput_input__focused {
  color: black !important;
  height: 30px;
  margin-left: 6px;
  padding: 0;
  // border-radius: 3px;
  border-bottom: 1px solid $primary;
  box-shadow: none;
  font-size: 0.9rem;
  font-weight: 600;
}

.reportDatePicker .DateRangePickerInput {
  border: none !important;
  background: transparent;
}

.reportDatePicker .DateRangePicker .CalendarDay__selected_span,
.reportDatePicker .DateRangePicker .CalendarDay__selected {
  background: $primary;
  border-color: $primary;
  color: white;
}

.download {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
