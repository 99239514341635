@import 'config/colors.scss';

.buttons-container {
  display: flex;
  padding: 10px;
  padding-top: 50px;
  list-style: none;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 12px;
  flex: 0 0 auto;
  align-self: center;
  align-items: center;
  height: auto;
}

.info-container {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabButtonsActive {
  color: $positive;
  background-color: transparent;
  font-size: 16px;
  border: none;
  outline: none;
  text-align: left;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 700;
}

.tabButtons {
  color: $positive;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  border: none;
  outline: none;
  text-align: left;
  margin-bottom: 15px;
  cursor: pointer;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.profile-container {
  background-color: $background-light-6;
}

.candidate-profile-header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 24px 36px 24px;
  align-items: center;
}

.profile-details-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
}

.button-styles,
.button-stylesActive {
  padding: 10px;
  border-bottom: 1px solid $positive;
  padding: 2px;
}

.scroll-view-container {
  height: 100%;
  box-sizing: border-box;
  padding: 8px 12px 0px 12px;
}

.button-styles:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  padding-top: 0px;
  height: auto;
  margin-bottom: 0px;
}

.tabButtons,
.tabButtonsActive {
  font-size: 16px;
  margin-bottom: 0px;
}

.button-styles {
  border-bottom: 1px solid rgba(164, 194, 244, 0.4);
  padding-bottom: 2px;
  padding: 2px;
}
