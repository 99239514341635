@import 'config/colors.scss';

.timeline-bg {}
  .timeline-bg .ignore-timeline-bg {
    background: #ffffff; }

.cd-timeline-wrapper {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding-left: 1em;
  padding-right: 1em;
  flex: 1 1 auto;
}

.cd-timeline {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 1em 0 1em 0;
}
  .cd-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 24px;
    height: 100%;
    width: 4px;
    background: $line;
    border-radius: 4px;
  }
    .mid .cd-timeline::before {
      left: 50%;
      margin-left: -2px; }

.cd-timeline-block {
  position: relative;
  margin: 1em 0;
  color: $brand;
 }
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both; }
  .cd-timeline-block:first-child {
    margin-top: 0; }
  .cd-timeline-block:last-child {
    margin-bottom: 0; }
    .mid .cd-timeline-block {
      margin: 4em 0; }
      .mid .cd-timeline-block:first-child {
        margin-top: 0; }
      .mid .cd-timeline-block:last-child {
        margin-bottom: 0; }

.cd-timeline-img {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  // border: 3px solid #d7e4ed;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden; }
  .cd-timeline-img img, .cd-timeline-img i {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px; }
    .mid .cd-timeline-img {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px; }

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 12px;
  border: 1px solid $line;
  /*box-shadow: 0 1px 1px #dae1e3;*/
  /*box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);*/
}
  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both; }
  .cd-timeline-content h2 {
    color: #303e49;
    font-size: 16px; }
  .cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 13px;
    font-size: 0.8125rem; }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    display: inline-block; }
  .cd-timeline-content .button:first-child, .cd-timeline-content .filter-bar .has-filters .head button.toggle:first-child, .filter-bar .has-filters .head .cd-timeline-content button.toggle:first-child, .cd-timeline-content .filter-bar .has-filters .head button.open-hide:first-child, .filter-bar .has-filters .head .cd-timeline-content button.open-hide:first-child, .cd-timeline-content .filter-bar .has-filters .filter-props .filter-item .filter-button:first-child, .filter-bar .has-filters .filter-props .filter-item .cd-timeline-content .filter-button:first-child, .cd-timeline-content .filter-bar .has-filters .filter-props .buttons button:first-child, .filter-bar .has-filters .filter-props .buttons .cd-timeline-content button:first-child {
    margin-right: 10px; }
  .cd-timeline-content p {
    margin: 0.8em 0 1em 0;
    line-height: 1.6;
    color: #666666;
  }
  .cd-timeline-content .unread-icon {
    position: absolute;
    right: 7px;
    bottom: 3px;
    color: #999; }
  .cd-timeline-content .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: $line;
    color: #ffffff;
    border-radius: 0.25em; }
    .no-touch .cd-timeline-content .cd-read-more:hover {
      background-color: $line }
  .cd-timeline-content .cd-date {
    float: left;
    color: #999999;
    // padding-top: 3px;
  }
  .cd-timeline-content .cd-date svg {
    margin-right: 4px;
  }
  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    margin-right: 2px;
    border: 7px solid transparent;
    border-right: 7px solid $line; }
    .small .cd-timeline-content h2 {
      font-size: 20px;
      font-size: 1.25rem; }
    .small .cd-timeline-content p {
      font-size: 16px;
      font-size: 1rem; }
    .small .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
      font-size: 14px;
      font-size: 0.875rem; }

    .mid .cd-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%; }
      .mid .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: #ffffff; }
      .mid .cd-timeline-content .cd-read-more {
        float: left; }
      .mid .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem; }
      .mid .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right; }
        .mid .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
          top: 24px;
          left: auto;
          right: 100%;
          border-color: transparent;
          border-right-color: #ffffff; }
        .mid .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
          float: right; }
        .mid .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
          left: auto;
          right: 122%;
          text-align: right; }
