@import 'config/colors.scss';

.calendarContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dayOfWeekContainer {
  display: flex;
  flex-direction: row;
  position: relative
}

.dayName {
  box-sizing: border-box;
  width: 14.28%;
  text-align: right;
  font-size: 0.9rem;
  padding: 3px 0px;
}

.dayText {
  margin-right: 5px;
  color: $text;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
}

.weekdayText {
  margin-right: 3px;
  font-size: .9rem;
  color: $text;
  font-weight: 500;
}

.dayTextGray {
  color: #696969;
}

.dayTextBlue {
  color: $positive;
  font-weight: bold;
}

.dayTextCurrentDay {
  color: $primary;
  font-weight: 700;
}

.dayRightBorder {
  border-right: 1px solid #eaeaea;
}

.calendarDayContainer {
  flex: 1 1 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
}

.calendarDay {
  box-sizing: border-box;
  width: 14.28%;
  height: 20%;
  text-align: right;
  border-top: 1px solid #eaeaea;
  position: relative;
  overflow: hidden;
}

.calendarDay--hoverable:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.calendarDayBorderRight {
  border-right: 1px solid #eaeaea;
}

.calendarDay--current {
  border: 1px solid $primary;
}

.grayDay {
  background-color: $background-light-5;
  color: #cfcfcf;
}

.event {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
