@import 'config/colors.scss';

.toggleContainer {
  display: flex;
  flex-direction: row;
  // border: 1px solid $text;
  border-radius: 3px;
  align-items: center;
  color: $text;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 500;
  > * {
    margin-right: 3px;
    &:last-child { margin-right: 0; }
  }
}

.option {
  padding: 0 2px 0 2px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $background-light-2;
  height: 40px;
}

.selected {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  color: $text;
  height: 40px;
}
