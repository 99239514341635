.settingItem {
  padding: 25px 5px 27px 5px;
  margin: 0 20px 0 20px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  color: #999999;
}

.settingItem:last-child {
  border-bottom: none;
}

.settingItem .expandButton {
  position: absolute;
  top: 30px;
  right: 0;
}

.settingItem h3 {
  font-weight: normal;
  font-size: 1.4em;
  margin-bottom: 12px;
  color: #333333;
}

.settingItem p {
  font-size: 1.15em;
}

.settingItem .settingItemExpand {
  /*transition: max-height 0.8s ease;*/
  max-height: 0;
	overflow: hidden;
  color: #666666;
}

.settingItem .settingItemExpand.expanded {
  max-height: 2000px;
  padding-top: 20px;
  padding-left: 10px;
}
