@import 'config/colors.scss';

.closeButtonContainer {
  background-color: none;
  height: 20px;
  border-radius: 50%;
}

.closeButtonContainer:hover {
  background-color: $text;
}

.closeButtonContainer svg {
  color: $text;
  padding-bottom: 7.5px;
  cursor: pointer;
}

.closeButtonContainer:hover svg {
  color: white;
}

.inverted {
  background-color: none;
  height: 20px;
  border-radius: 50%;
}

.inverted svg {
  color: $black;
  padding-bottom: 7.5px;
  cursor: pointer;
}
