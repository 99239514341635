@import 'config/colors.scss';
@import 'config/spacing.scss';

.contract-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;

  &__missing-contract {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  
  &__missing-contract-text {
    color: #666;
    margin-bottom: 12px;
  }
}

.tab-sub-header-bar {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid $grey-border;

  &__title {
    font-size: 18px;
    color: #333;
    font-weight: 500;
  }

  &__changes-text {
    color: #666;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }
}

.contract-form {
  
  padding: 12px;
  width: 600px;

  &__hours-fields {
    display: flex;


    @media only screen and (max-width: 650px) {
      padding-bottom: 24px;
    }
  }
  
  &__row {
    display: flex;
    padding-bottom: 24px;
    justify-content: space-between;

    @media only screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__date-picker {
    width: 300px;
    border-radius: 2px;
    position: relative;

    @media only screen and (max-width: 500px) {
      width: 240px;
    }
  }

  &__label {
    font-size: 14px;
    color: #666;
    font-weight: 500;
  }

  &__field-container {
    display: flex;
    flex-direction: column;
  }

  &__hours-per-text {
    align-self: flex-end;
    margin-bottom: 3px;
    color: #999;
    margin-left: -52px;
  }

  &__period-type-field {
    align-self: flex-end;
  }

  &__hours-input {
    font-size: 16px;
  }

  /* This hides up/down arrows on html number input */
  &__hours-input::-webkit-outer-spin-button,
  &__hours-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__error-banner {
    background-color: $red;
    display: flex;
    padding: 12px;
    p {
      color: $white;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  &__error {
    font-size: 14px;
    line-height: 1.4;
    color: $red;
  }
}