@import 'config/colors.scss';

.chartWrapper {
  position: relative;
  padding: 12px;
  /* z-index: -1; */
}

.chartWrapper tspan,
.chartWrapper span,
.chartWrapper p,
.chartWrapper text {
  font-size: 0.9rem;
}

.chartWrapper .xAxis {
  font-size: 0.8rem;
  color: $text;
}

.chartWrapper .recharts-legend-item-text {
  color: $text;
}

.chartWrapper .recharts-cartesian-axis-tick {
  color: #999;
}

.chartWrapper .recharts-tooltip-cursor,
.chartWrapper .recharts-bar-rectangle {
  cursor: pointer;
}

.chartWrapper .recharts-default-tooltip {
  border: none !important;
  border-radius: 6px !important;
  box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 0 0 1px rgba(0,0,0,.07);
}

.chartWrapper .recharts-default-tooltip .recharts-tooltip-label {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px !important;
}

.chartWrapper h3,
.report-title {
  text-transform: uppercase;
  margin: 6px;
  font-size: 1rem;
  margin-left: 12px;
  color: $text;
  font-weight: 500;
}

.report-explanation {
  margin-left: 12px;
  color: $black;
  font-size: 0.9rem;
}

.report-total {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 16px;
  margin-top: 16px;
  color: $text;
  color: $text;
  font-weight: 600;
}

.trend {
  position: absolute;
  right: 1rem;
  top: 1rem;
  text-align: center;
  border: 1px solid #e2e8ea;
  border-radius: 3px;
  padding: 0;
  background-color: rgba(255,255,255,0.8);
}

.trend h4 {
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  padding-top: 0.3rem;
}

.trend h4.trendPositive {
  color: #1EC06A;
}

.trend h4.trendNegative {
  color: #E23C3F;
}

.trend h4 i.fa {
  display: inline-block;
  margin-right: 4px;
}

.trend span {
  text-transform: uppercase;
  color: #a1abb3;
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 2em;
  display: inline-block;
  padding: 0 0.5rem;
  text-align: center;
}

.reportLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleLegend {
  display: flex;
  justify-content: space-between;
}

.toggleLegend .toggleLegendList {
  color: $text;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 0 20px 0;
  font-size: 0.9rem;
  text-align: left;
}

.toggleLegend .toggleLegendList .legend-item {
  padding: 7px 10px 7px 10px;
  display: inline-block;
  cursor: pointer;
  font-weight: 500 !important;
  border-radius: 3px;
  outline: none;
}

.toggleLegend .toggleLegendList .legend-item:hover {
  background-color: #e0effc !important;
}

.toggleLegend .toggleLegendList .legend-item-non-clickable {
  padding: 7px 10px 7px 10px;
  display: inline-block;
  font-weight: 500;
}

.toggleLegend input {
  margin-left: 5px;
  display: inline-block;
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 5px;
}
