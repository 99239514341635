@import 'config/colors.scss';

.sidePanelContainer {
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
  height: 100%;
  z-index: 5000;
  width: 400px;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  
  &.sidePanelContainerRight { right: -360px; box-shadow: -8px 3px 6px -7px rgba(0,0,0,0.4); }
  &.sidePanelContainerLeft  { left: -360px;  box-shadow: 8px 3px 6px -7px rgba(0,0,0,0.4);  }
}

.sidePanelTitleBar {
  height: 50px;
  width: 100%;
  background-color: $background-light-2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  flex: 0 0 auto;
  padding: 6px;
}

.sidePanelTitleTextContainer {
  text-align: center;
  width: 100%;
}

.sidePanelTitle {
  color: $text;
  font-size: 1.4rem;
  font-weight: 600;
}

.sidePanelContainerOpen {
  animation-fill-mode: backwards;
  &.sidePanelContainerRight { transform: translateX(-360px); animation: slideFromRight 0.2s ease; }
  &.sidePanelContainerLeft  { transform: translateX(360px);  animation: slideFromLeft 0.2s ease;  }
}

.sidePanelCloseButton {
  cursor: pointer;
  font-size: 1.3rem;
  color: $black;
  position: absolute;
  top: 8px;
  right: 12px;
}

.sidePanelPinButton {
  cursor: pointer;
  font-size: 18px;
  color: $black;
  position: absolute;
  top: 14px;
  right: 40px;
}
// .sidePanelContainerRight .sidePanelCloseButton { right: 12px; }
// .sidePanelContainerLeft  .sidePanelCloseButton { left: 12px;  }

.sidePanelContentContainer {
  flex: 1 1 0px;
  height: 0px; /* fixes ie / safari */
  overflow: hidden;
}

.sidePanelContentContainerVerticalFlex {
  display: flex;
  flex-direction: column;
}

@keyframes slideFromRight {
  0%   { transform: translateX(0); }
  100% { transform: translateX(-360px); }
}

@keyframes slideFromLeft {
  0%   { transform: translateX(0); }
  100% { transform: translateX(360px); }
}
