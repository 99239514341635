/* Select component styles */
@import 'config/colors.scss';

.cs-react-select__control {
  margin-bottom: 6px;
  background-color: white !important;
  height: 15px !important;
  border: none !important;
  border-radius: 0px !important;
}

.cs-react-select__indicator-separator {
  display: none;
}

.cs-react-select__dropdown-indicator {
  margin-bottom: 9px;
  color: $positive !important;
}

.cs-react-select__value-container {
  cursor: pointer;
  margin-bottom: 7px;
  padding: 0 !important;
  margin-left: 5px;
}

.cs-react-select__menu {
  margin: 0px;
  border: none !important;
  border-radius: 0px !important;
}

.cs-react-select__option {
  color: #999999 !important;
  cursor: pointer !important;
  border-radius: 0;
}

// .cs-react-select__option--is-selected {
//   background-color: $primary !important;
//   color: #fff !important;
// }

.cs-react-select__control--is-focused {
  border-color: #cccccc !important;
  box-shadow: none !important;
  background-color: white !important;
  border: none !important;
  border-radius: 0px !important;
  position: none !important;
}

.cs__description-text-area {
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  padding: 6px;
  font-size: 0.9rem;
  font-family: 'Inter';
  line-height: 1.45;
  resize: none;
  color: hsl(0,0%,20%);
  font-weight: 300;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  outline: none;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid $primary;
  }
}

.cs__description-text-area::placeholder,
.cs__description-text-area::-ms-input-placeholder,
.cs__description-text-area::-webkit-input-placeholder {
  font-style: normal;
  /* color: red; */
}

.cs__time-pickers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cs__time-pickers__indiv {
  display: flex;
  margin: auto;
  align-items: center;
  margin-left: 0;
}

.cs__time-pickers__label {
  margin-right: auto;
}

.cs__time-pickers .cs__time-picker {
  margin: 6px;
  margin-bottom: 2px;
  height: 45px;
}

.cs__button-container {
  display: flex;
  align-items: center;
  justify-content: stretch;

  > button {
    flex: 1;
    margin-left: 12px;
    &:first-child { margin-left: 0; }
  }
}

.cs__heading {
  font-weight: 200;
}

.createShiftDatePicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: hsl(0,0%,80%);
  // border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  flex: 1;
}

.DateInput {
  background: transparent;
}

.createShiftDatePicker .SingleDatePickerInput_calendarIcon {
  background: transparent;
}

.createShiftDatePicker .SingleDatePickerInput .DateInput_input {
  background: transparent;
}

.createShiftDatePicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.createShiftDatePicker .SingleDatePickerInput {
  border: none !important;
  background-color: transparent;
  display: flex;
  flex-direction: row;
}

.createShiftDatePicker .SingleDatePickerInput_calendarIcon {
  background-color: #ffffff;
  font-size: 14px;
  padding: 0 9px;
  margin: 0;
  margin-top: 1px;
  outline: none !important;
}

.createShiftDatePicker .SingleDatePickerInput_calendarIcon_svg {
  fill: $primary !important;
  margin-bottom: 5px;
}

.createShiftDatePicker .SingleDatePickerInput .DateInput_input {
  border: none !important;
  box-shadow: none !important;
  padding: 6px 9px;
  padding-right: 0;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Inter';
  font-weight: normal !important;
  color: #000 !important;
}

.createShiftDatePicker .SingleDatePickerInput .DateInput_input__focused {
  border: none !important;
}

.cs__validation-messages p {
  margin-bottom: 6px;
  color: $error;
  line-height: 1.25;
  font-size: 0.9rem;
  font-weight: 500;
}

.cs__validation-message p:last-child {
  margin-bottom: 0;
}

.cs__time-pickers .cs__time-picker {
  height: auto;
}

.css-1492t68.react-select__placeholder{
  font-style:italic;
  color: #9F9F9F;
}

.rc-time-picker-input {
  font-size: 16px;
  font-weight: 300;
}

.rc-time-picker-panel-input {
  font-size: 16px;
  font-weight: 300;
}

.rc-time-picker-panel-select li {
  font-size: 16px;
  height: 28px;
  line-height: 28px;
}

.cost-centre__label {
  color: #B7B7B7;
  width: 100px;
}

.cost-centre__container {
  font-size: 15px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.multiple-drafts-footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid $line;
}

.multiple-drafts-nav {
  margin-bottom: 12px;
}

.multiple-drafts-nav__text {
  color: $text;
  font-size: 0.9rem;
  padding: 12px;
}

.multiple-drafts-nav__buttons-container {
  display: flex;
  justify-content: space-between;
}

.drafts-event__count {
  background-color: $primary;
  border-radius: 100%;
  color: #fff;
  width: 30px;
  padding: 7px 0;
  align-self: center;
}

.drafts-event__count-placeholder { opacity: 0 }

.calendarDay:hover .drafts-event__count-placeholder {
  background-color: $primary;
  border-radius: 100%;
  color: #fff;
  width: 30px;
  padding: 7px 0;
  visibility: visible;
  align-self: center;
  opacity: .5;
}

.drafts-event__decrement-button {
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 1;
  font-size: 24px;
}

.drafts-event__container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.calendarDay.calendarDay--disabled { cursor: auto }

.shiftCheckboxItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.shiftCheckboxItemLabel {
  font-size: 16px;
  color: #666;
  margin-left: 12px;
  margin-top: 2px;
}

.shiftAutobookDetail {
  font-size: 13px;
}

.hourly-rate-input, .slots-input {
  display: inline-block;
  font-size: 0.9rem;
  color: $text;
  box-sizing: border-box;
  width: 100%;
  padding-left: 12px;
  outline: none;
  border: 1px solid $line;
  border-left-width: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  &:focus {
    border-color: $positive;
    border-width: 1;
    border-style: solid;
    border-left-width: 0px;
  }

  &--disabled {
    background-color: $border;
  }

  &--missing {
    border-color: $red;
    border-width: 1;
    border-style: solid;
  }
}

.slots-input {
  border-left-width: 1px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-left: 8px;
  color: $text;
  font-size: 0.9rem;

  &:focus {
    border-color: $primary;
    border-width: 1;
    border-style: solid;
    border-left-width: 1px;
  }

  &__button {
    padding: 0 9px;
    color: #333;
    font-size: 1em;
    cursor: pointer;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid #CCC;

    &--invalid {
      border-color: $red;
      border-width: 1;
      border-style: solid;
    }
  }
}

.hourly-rate-input:focus + .hourly-rate-currency-box {
  border: 1px solid $positive;
}

.hourly-rate-currency-box {
  border: 1px solid $line;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 12px;
  background-color: $background-focussed;
  color: $text;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  &--disabled {
    cursor: default;
  }

  &--missing {
    border-color: $red;
    border-width: 1;
    border-style: solid;
  }

  &--focussed {
    border: 1px solid $positive;
  }
}

/* Chrome, Safari, Edge, Opera */
.hourly-rate-input::-webkit-outer-spin-button,
.hourly-rate-input::-webkit-inner-spin-button,
.slots-input::-webkit-outer-spin-button,
.slots-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hourly-rate-input, .slots-input {
  -moz-appearance: textfield;
}