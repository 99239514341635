@import 'config/colors.scss';

.optionList {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.optionList .item {
  flex: 1;
  background-color: #fff;
  color: $text;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  text-align: center;
  padding: 6px;
  border: 1px solid $black;
}

.optionList .item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 0;
}

.optionList .item.selected {
  background-color: $black;
  border: 1px solid $positive;
  color: white;
}

.optionList .item:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
