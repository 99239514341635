.more-button {
  display: flex;
  position: relative;

  &__menu {
    position: absolute;
    top: 116%;
    background-color: #FFF;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
    z-index: 100000;
    width: auto;
    white-space: nowrap;

    & div:not(:last-child) {
      border-bottom: 1px solid #e6e6e6;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__sub-button {
    padding: 12px 18px;
    overflow: visible;

    color: #333;
    font-size: 15px;
    line-height: 1;
  }

  &__sub-button:hover {
    background-color: rgba(0, 94, 134, 0.2);
    outline: none;
    cursor: pointer;
  }
}