@import 'config/colors.scss';

.shiftDetailsTabsContainer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.sidePanelScrollViewBookingContainer {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.button-styles {
  align-items: center;
}

.shiftDetailsTabButtonsActive {
  color: $text;
  background-color: white;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  width: 100%;
  // border-right: 1px solid rgba(164, 194, 244, 0.3);
  padding: 18px 0;
  line-height: 1;
}

.bookingsHeading {
  padding: 12px 12px 0px 12px;
  color: $text;
  font-size: 1.2rem;
  font-weight: 500;
}


.shiftDetailsTabButtons {
  color: $text;
  background-color: $background-light-1;
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  // border-right: 1px solid rgba(164, 194, 244, 0.3);
  padding: 18px 0;
  line-height: 1;
}

.shiftDetailsTabButtons:hover {
  background-color: $background-focussed;
}

.shiftDetailsViewContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.shiftDetailContentContainer {
  color: #666;
  flex: 1 1 0px;
  height: 0px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.shiftDetailsSubSection {
  flex: 0 0 auto;
  background-color: #fff;
  margin-bottom: 0px;
  padding: 12px;
  &:last-child { padding-bottom: 12px; }
}

.additionalDetailsBoldHeading {
  font-size: 0.9rem;
  font-weight: 500;
  color: #999;
  justify-content: flex-start;
  flex: 1 0 36%;
  line-height: 1.3;
}

.additionalDetailsValue {
  flex: 1 0 68%;
  color: $text;
}

.detail-list-item {
  font-size: 0.9rem;
  word-break: break-all;
}

.shiftDetailsSubSectionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shiftDetailsSubSectionTitleBooking {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.bookingTitleBox {
  justify-content: flex-start;
}

.viewsApplicantsBox {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  color: hsla(0, 0%, 72%, 1);
}

.appliesText {
  margin-left: 36px;
}

.shiftDetailsWarning {
  background-color: #F5B26C;
  color: #f4e4d3;
  padding: 6px 12px;
  color: white;
  line-height: 1.25;
  font-size: 15px;
  flex: 0 0 auto;
}

.shiftDetailsWarning svg {
  float: left;
  height: 30px;
  width: 30px;
  margin-top: 3px;
  margin-right: 12px;
}

.shiftDetailsSubHeading {
  color: $text;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 12px;
}

.applicantsHeading {
  color: $text;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 12px;
  padding-left: 12px
}

.shiftDetailsSubSectionContent {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  position: relative;
}

.shiftDetailsBoldHeadings {
  font-weight: 500;
  color: #999;
  justify-content: flex-start;
  width: 36%;
  font-size: 0.8rem;
}

.manage-agency-section {
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  position: relative;
  margin-bottom: 12px;
}

.manage-agency-section__status-label {
  font-weight: 500;
  color: #999;
  justify-content: flex-start;
  width: 32%;
}

.manage-agency-section__status-value {
  color: $text;
}

.agencyDetailsBoldHeadings {
  font-weight: 500;
  color: #999;
  justify-content: flex-start;
  width: 40%;
}

.agencyStatusAndReleaseBox {
  width: 70%;
}

.shiftDetailsBookingsButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shiftDetailsBookingButtonGreen {
  background-color: #33cd5f;
}

.blankButton {
  width: 125px;
  padding: 0 6px 0 6px;
  margin: 2px;
  height: 18px;
  background-color: transparent;
}

.shiftDetailsButtonContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.shiftDetailsBookingsButton {
  margin-top: -8px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
  width: 35%;
  height: 40px;
  box-shadow: 1px 4px 5px -5px rgba(133,129,133,1);
}

.agencyActionButtons {
  margin-top: auto;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
  width: 35%;
  height: fit-content;
  box-shadow: 1px 4px 5px -5px rgba(133,129,133,1);
}

.retractAgencyActionButtons {
  margin-top: -2px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
  width: 35%;
  height: fit-content;
  box-shadow: 1px 4px 5px -5px rgba(133,129,133,1);
}

.shiftDetailEditButton {
  height: 30px;
  width: 30px;
  background-color: $positive;
  position: absolute;
  right: 15px;
  align-items: center;
  border-radius: 2px;
  box-shadow: 1px 4px 5px -5px rgba(133,129,133,1);
  padding-left: 6px;
  cursor: pointer;
}

.bookingsCard,
.target-bank-candidate-container,
.applicationsSection {
  background-color: #fff;
  border-radius: 3px;
}

.bookingsCard {
  // border-bottom: 1px solid black;
}

.applicationsSection {
  padding: 12px 0;
}

.bookingsSection {

  padding: 12px 0;
}

.bookings-tab-content-container:hover,
.applicationsCard:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.target-bank-candidate-container {
  padding: 24px 12px
}

.bookingsSubHeading,
.applicationsSubHeading {
  flex: 1 1 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
  font-size: 1rem;
  font-weight: 500;
  color: $text;
}

.bookingInfoText,
.candidateInfoText {
  text-overflow: ellipsis;
  color: $light-text;
  font-size: 0.9rem;
}

.bookingsTabButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;
}

.applicationsCard {
  /* margin-bottom: 16px; */
  padding-top: 16px;
}

.applicationsCard:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.shiftDetailsValues {
  color: $text;
}

.shiftDetailsSubHeadingApplications {
  color: $positive;
  font-size: 20px;
  margin-bottom: 18px;
}

.applicationsCardTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.select-box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  margin-bottom: 8px;
}

.target-candidate-select-box {
  flex: 1 1 0px;
  position: relative;
}

.auto-book-button {
  flex: 0 0 auto;
  margin-left: 12px;
}


.label-container {
  margin-bottom: 8px;
}

.shift-details-sub-heading-container {
  color: $positive;
  font-size: 24px 12px;
}

.label-text {
  color: #999999;
}

.autobook-text-container {
  margin: 4px 0 4px 0;
}

.autobook {
  color: white;
  background-color: hsla(209, 79%, 70%, 1);
  width: 35%;
  border-radius: 0.1em;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.booking-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 12px;
}

.user-feedback-container {
  height: 36px;
  display: flex;
  justify-content: center;
  flex: 1;
}

.auto-book-error-message {
  font-size: 14px;
  color: #e06666;
}

.select-loading-container {
  position: absolute;
  top: -1px;
  right: 42px;
}


.shiftDetailsUploadCandidateButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px;
}

.name-flex-container {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.book_candidate_info__container {
  margin-bottom: 12px;
}

.book_candidate_info__text{
  font-size: 0.9rem;
  line-height: 1.2;
  color: $text;
}

.shiftStatusContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 14px;
}

.agency-status__label {
  color: #999;
  width: 85px;
}

.agency-status__value {
  margin-left: 12px;
  width: 200px;
}

.agency-release__value {
  margin-left: 10px;
  width: 200px;
}

.shift-booking-content-container {
  padding: 24px 12px;
}

.shift-booking-content-container:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}


.shiftDetailsFooter {
  margin-left: auto;
  padding: 12px 0px 24px 0px;
}

.side-panel-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applicant-rating {
  display: flex;
  align-items: center;
  margin-left: 6px;
  position: relative;
  bottom: 1px;
}
