@import 'config/colors.scss';

.notificationContainer {
  position: relative;
  left: 0;
  border-bottom: 1px solid #e6e6e6;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  padding-bottom: 3px;
}

.notificationContainer:hover {
  background-color: #fbfbfb;
}

.notificationDataContainer {
  width: 100%;
}



.notificationContainer:hover .notificationRemoveContainer {
  display: flex;
  align-items: center;
  color: #999999;;
  background-color: #fbfbfb;
  padding-right: 10px;
  opacity: 1;
}

.notificationTopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 9px;
  right: 6px;
}

.notificationMessageContainer {
  padding: 6px 0 0 12px;
  font-size: 0.9rem;
  line-height: 1.3;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-line;
  color: #666;

  &.notificationMessageContainerRead { color: $understated; }
  &.notificationMessageContainerUnread { color: $text; }
}

.notificationTimeContainer {
  padding-left: 12px;
  margin-bottom: 4px;
  font-size: 0.8rem;
  &.notificationTimeContainerRead { color: #999; }
  &.notificationTimeContainerUnread { color: $understated; }
}

.notificationRemoveContainer {
  opacity: 0;
  padding-right: 10px;
}

.removedNotification {
  /* animation: remove 0.3s linear forwards; */
}

@keyframes remove {
  0% {
    left: 28px;
  }
  10% {
    left: 56px;
  }
  20% {
    left: 84px;
  }
  30% {
    left: 112px;
  }
  40% {
    left: 140px;
  }
  50% {
    left: 168px;
  }
  60% {
    left: 196px;
  }
  70% {
    left: 224px;
  }
  80% {
    left: 252px;
  }
  90% {
    left: 280px;
  }
  100% {
    left: 360px;
  }
}
