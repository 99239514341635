@import 'config/colors.scss';

.standardEventComponent {
  display: block;
  margin: 0 auto;
  max-height: 60px;
  width: 90%;
  opacity: 0.9;
}

.standardEventComponentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.standardEventComponent.small {
  top: 16px;
}

.standardEventComponent.small .standardEventComponentContent {
  padding: 1px;
}

.standardEventComponent:hover {
  opacity: 1;
}

.standardEventComponentContent {
  background-color: $positive;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 70%;
  color: white;
  padding: 3px;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px hsla(100, 4%, 15%, 0.5);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 3px;
  height: 19px;
}

.standardEventComponent.unfilled .standardEventComponentContent {
  background-color: #e06666;
}

.standardEventComponent.filled .standardEventComponentContent {
  background-color: #33cd5f;
}

.standardEventComponent.filled.past .standardEventComponentContent {
  background-color: hsla(137, 61%, 50%, 0.5);
}

.standardEventComponent.unfilled.past .standardEventComponentContent {
  background-color: hsla(0, 66%, 64%, 0.5);
}
