.filterItemContainer {
  margin-right: 0;
  padding: 0 3px 0 3px;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;


  &:hover, &:focus {
    text-decoration: underline;
  }
}

.filterItemContainer svg {
  // margin-left: 6px;
  transition: all .3s ease;
}

.filterItemContainer:hover svg {
  color: rgb(0, 94, 134);
}
