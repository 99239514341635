::-webkit-input-placeholder { /* Chrome */
    color: #9F9F9F;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #9F9F9F;
}
  ::-moz-placeholder { /* Firefox 19+ */
    color: #9F9F9F;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: #9F9F9F;
    opacity: 1;
  }