@import 'config/colors.scss';
@import 'config/spacing.scss';

.inboxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 12px;
  border-bottom: 1px solid $line;
  background-color: $background-light-6;
}

.inboxHeader p {
  color: $text;
  font-weight: 600;
  font-size: 1.5rem;
}

.inboxError {
  background-color: $red;
  color: $white;
  padding-left: $base;
}

.inboxContentContainer {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.messagingListPanelContainer {
  border-right: 1px solid $grey-border;
  max-width: 360px;
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
}

.messagingListPanelHeader {
  border-bottom: 1px solid $grey-border;
  padding: $base;
}

.messagingListItemScroll {
  overflow: auto;
  overflow-y: scroll;
  flex: 1;
}

.messagingListItemContainer {
  height: 50px;
  color: $text;
  padding: $base;
  font-size: 14px;
  cursor: pointer;
}

.messagingListItemContainer:hover {
  background-color: $background-focussed;
}

.messagingListItemNotSeen {
  font-weight: 700;
}

.messagingListItemSelected {
  background-color: #f7f7f7;
}

.messagingListItemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.messagingListItemTitle div:nth-child(1) {
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.messagingListItemMessage {
  font-size: 0.9rem;
  // line-height: 1;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  // max-height: 42px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.messagingListItemNewMessage {
  display: flex;
  height: 100%;
  align-items: center;
}

.messagingConversationContainer {
  overflow: hidden;
  flex: 1;
  font-size: 14px;
  color: $text;
}

.messagingConversationLoadingContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messagingMessageBubbleContainer {
  // min-height: 33px;
  margin-top: $base;
  margin-bottom: $base;
  overflow: hidden;
  // max-width: 60%;
}

.messagingMessageStatus {
  float: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.messagingMessageBubble {
  border-radius: 8px;
  padding: 12px;
  white-space: pre-wrap;
  font-size: 0.9rem;
}

.messageLeft {
  color: $black;
  background-color: $background-light-6;
  float: left;
}

.messageRight {
  color: $text;
  background-color: rgba(163, 185, 195, 0.3);
  float: right;
}

.messagingConversationDate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;
  color: #999;
}

.messagingComposeMessageContainer {
  display: flex;
  align-items: center;
  padding: $base;
  padding-bottom: 0;
}

.messagingComposeMessageTextArea {
  background-color: $background-light-5;
  border-radius: $large;
  outline: none;
  color: $text;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: none;
  resize: none;
  margin-right: $base;
  font-size: 1rem;

  &--long {
    min-height: 160px;
    background-color: white;
    outline: 1px solid $grey-border;
    margin-right: 0;
    border-radius: 0;
  }
}

.messagingComposeMessageSend {
  cursor: pointer;
}

.messagingConversationMessageContainer {
  box-sizing: border-box;
  padding: 12px;
  overflow-y: auto;
  height: 100%;
}

.messagingMessageLabel {
  width: 100%;
  display: flex;
  // margin-top: 3px;
  font-size: 0.9rem;
  color: #999;
  margin-bottom: 6px;
}

.messagingMessageLabelLeft {
  justify-content: flex-start;
  margin-top: 3px;
}

.messagingMessageLabelRight {
  justify-content: flex-end;
  margin-top: 3px;
}

.messagingInboxDetailsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.messagingInboxDetailsHeader {
  // height: 36px;
  width: 100%;
  align-items: center;
  padding: $base;
  color: $text;
  font-size: 0.9rem;
  font-weight: 600;
  border-bottom: 1px solid $line;
}

.messagingInboxDetailsHeader a {
  cursor: pointer;
  font-size: 0.9rem;
  &:hover { text-decoration: underline; }
}

.messagingFromToContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  color: $text;
  border-bottom: 1px solid $grey-border;
  min-height: 48px;
  padding: 0 $base;

  > p {
    width: 60px;
    font-weight: bold;
  }
}

.messagingFromSelectContainer {
  flex: 0 0 auto;
  width: 280px;
  margin-right: $large;
  font-weight: 500;
}

.messagingNewMessageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.messagingNewMessageLoadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.messagingStaffListLink:hover {
  cursor: pointer;
  text-decoration: underline;
}