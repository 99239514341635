@import 'config/colors.scss';
@import 'config/spacing.scss';

@mixin colorVariantStyles($className, $color) {
  &__main-button--#{$className} {
    color: $white;
    background-color: #{$color};
    border-color: #{$color};

    &.split-button__main-button--outline {
      color: #{$color};
      background-color: $white;
    }

    // &.split-button__main-button--clickable:hover:not(.split-button__main-button--disabled) {
    //   color: #{$color};
    //   background-color: $white;
    //   border-color: #{$color};
    //   opacity: 0.8;
    // }
    &.split-button__main-button--disabled {
      opacity: 0.5;
      cursor: default;
      &.split-button__main-button--clickable:hover { opacity: 0.5; }
    }
  }

  &__chevron--#{$className} {
    background-color: #{$color};
    color: $black;
    border-color: #{$color};

    &.split-button__chevron--outline {
      color: #{$color};
      background-color: $white;
    }

    // &:hover {
    //   opacity: 0.8;
    // }
  }

  &__divider--#{$className} {
    background-color: $white;
    &.split-button__divider--outline {
      background-color: $color;
    }
  }
}


.split-button {
  display: flex;
  position: relative;
  border: 1px solid $line;
  border-radius: 4px;

  &--small {
    align-items: flex-end;
  }
  
  &__main-button {
    box-sizing: border-box;
    display: inline-block;
    padding: 9px 12px 9px 12px;
    border: none;
    border-style: solid;
    border-width: 1px;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid black;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    color: $black;
    background-color: $white;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    &.split-button__main-button--no-menu-items {
      border-radius: 4px;
    }

    &.split-button__main-button--clickable {
      cursor: pointer;
    }

    &--small {
      font-size: 0.7em;
      padding: 0 12px;
      height: 24px;
    }
  }

  &__chevron {
    display: flex;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid $positive;
    border-left: 0;
    padding: 0 6px;
    color: $positive;

    &.split-button__chevron--clickable {
      cursor: pointer;
    }

    &--small {
      padding: 0 6px;
      height: 24px;
      line-height: 1;
    }
  }

  &__divider {
    width: 1px;
  }

  &__menu {
    position: absolute;
    top: 116%;
    background-color: #FFF;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
    z-index: 100000;
    width: 100%;
    white-space: nowrap;

    & div:not(:last-child) {
      border-bottom: 1px solid #e6e6e6;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__sub-button {
    padding: 12px;
    overflow: visible;

    color: $black;
    font-size: 0.9rem;
    line-height: 1;

    &--small {
      padding: 6px 12px;
      font-size: 0.7em;
    }

    &:hover {
      background-color: $background-focussed;
      outline: none;
      cursor: pointer;
    }

    &--disabled {
      background-color: $disabled;
      opacity: 0.5;
    }

    &--disabled:hover {
      opacity: 0.5;
      background-color: $disabled;
    }
  }

  @include colorVariantStyles("positive", $positive);
  @include colorVariantStyles("negative", $negative);
  @include colorVariantStyles("warning", $warning);
  @include colorVariantStyles("danger", $red);
  @include colorVariantStyles("create", $create);
  @include colorVariantStyles("white", $white);
  @include colorVariantStyles("black", $black);

}

.split-button__main-button--black,
.split-button__chevron--black {
  color: $white;

  &:hover:not(.split-button__main-button--disabled)  {
    background-color: #363030;
    border-color: #363030;
  }
}

.split-button__main-button--white,
.split-button__chevron--white {
  color: $black;
  
  &:hover:not(.split-button__main-button--disabled)  {
    color: $white;
    background-color: $black;
    border-color: $black
  }
}