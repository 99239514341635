@import 'config/colors.scss';

.submitApplicant {
  font-weight: normal;
  color: #666666;
  padding-bottom: 7px;
}

.submitApplicantHeading {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #333333;
  text-align: left;
  font-weight: bold;
  padding-bottom: 6px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-top: 20px;
  margin-left: 10px;
}

.submitApplicantHeading:first-child {
  margin-top: 5px;
}

.submitApplicantBody .candidate {
  display: flex;
  padding-top: 15px;
  align-items: center;
}

.submitApplicantBody .candidate img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
}

.submitApplicantBody .candidate .name {
  font-weight: normal;
  padding-left: 12px;
  font-size: 1.2em;
  color: #333333;
}

.submitApplicantBody .splitter {
  clear: both;
}

.submitApplicantItem {
  display: flex;
  color: #666666;
}

.submitApplicantItemLabel {
  width: 40%;
  text-transform: capitalize;
  margin-left: 10px;
}

.submitApplicantWarning {
  text-align: center;
  margin-top: 20px;
  margin-bottom: -7px;
  border-radius: 10px;
  background-color: $positive;
  color: white;
  padding: 5px;
  width: 85%;
  display: flex;
  margin-left: 7%;
  flex-direction: column;
  font-weight: bold;
}

.submitApplicationComplianceApproved {
  color: #33cd5f;
}

.submitApplicantStatus-enter {
  opacity: 0.01;
  position: absolute;
}

.submitApplicantStatus-enter.submitApplicantStatus-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}

.submitApplicantStatus-leave {
  opacity: 1;
  position: absolute;
}

.submitApplicantStatus-leave.submitApplicantStatus-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.uploadModalscrollHeight {
  max-height: 400px;
}

.uploadModalScrollView {
  height: 400px;
}
