
.icons-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker-line {
  width: 1px;
  background-color: hsl(0,0%,80%);
  height: 22px;
  margin: 0 -1px;
}

.icons-container:focus,
.date-picker-line:focus {
  outline: none;
}