@import 'config/colors.scss';

.splitViewLoading {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.splitViewWrapper {
  flex: 1 1 100%;
  display: flex;
  align-items: stretch;
}
.splitViewNoItems {}
.splitViewContent {
  flex: 1 1 0px;
  width: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-left: 1px solid #eaeaea;
}
.splitViewItems {
  flex: 0 0 220px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.splitViewItem {
  position: relative;
  padding: 12px;
  padding-right: 36px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.splitViewItem.selected {
  background-color: #f9f9f9;
}

.splitViewItem.selected .splitViewItemHeader {
  color: $positive;
}

.splitViewItems .splitViewItem:last-child {
  border-bottom: none;
}

.splitViewItemHeader {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #333333;
}
.splitViewItemDesc {
  color: #cccccc;
}
.splitViewItemIcon {
  color: #cccccc;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  height: 100%;
  top: 0;
  bottom: 0;
}
