@import 'config/colors.scss';

.dataBoxWrapper {
  position: relative;
  margin-top: 16px;
}

.dataBoxWrapper .rightGrad {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: box-shadow 250ms;
  transition: box-shadow 250ms;
  pointer-events: none;
  box-shadow: inset -120px 0px 100px -70px white;
}

.dataBoxWrapper .dataBox {
  display: flex;
}

.dataBoxWrapper .dataBox .freezeColumn {
  width: 130px;
  z-index: 2;
}

.dataBoxWrapper.scrolling .dataBox .freezeColumn {
  box-shadow: 5px 0 10px rgba(36,46,53,0.1);
}

.dataBoxWrapper .dataBox .dataScrollColumn {
  width: calc(100% - 100px);
  overflow-y: hidden;
  overflow-x: scroll;
}

.dataBoxWrapper .dataBox .dataScrollColumn .drillDownTable {
  display: flex;
}

.dataBoxWrapper .dataBox .drillDownTable .drillDownHeader {
  white-space: nowrap;
  background-color: $background-light-5;
  border-top: 1px solid $line;
  border-bottom: 1px solid $line;
  // padding: 0.7rem 1.125rem;
  padding: 12px;
  color: $text;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  position: relative;
  line-height: 1.2;
}

.dataBoxWrapper .dataBox .drillDownTable .drillDownCell {
  white-space: nowrap;
  line-height: 0.75;
  padding: 1rem 1.125rem;
  font-size: 0.90625rem;
  border-bottom: 1px solid rgba(36,46,53,0.1);
  cursor: pointer;
  background-position: 80% center;
  color: #666;
}

.dataBoxWrapper .dataBox .drillDownTable .withTooltip {
  display: flex;
  align-items: center;
}

.dataBoxWrapper .dataBox .freezeColumn .drillDownCell {
  cursor: default;
}

.dataBoxWrapper .dataBox .drillDownTable .drillDownColumn {
  cursor: default;
  flex: 1;
}

.dataBoxWrapper .dataBox .dataScrollColumn .drillDownTable .drillDownColumn .drillDownCell,
.dataBoxWrapper .dataBox .dataScrollColumn .drillDownTable .drillDownColumn .drillDownHeader {
  text-align: right;
}

.byRole {
  margin-top: 15px;
  margin-bottom: 60px;
}

.report-sub-title {
  margin-top: 40px;
  font-weight: 400;
  font-family: 'Oxygen', sans-serif;
  text-transform: uppercase;
  margin: 6px;
  font-size: 1.1em;
  margin-left: 12px;
  margin-bottom: 16px;
  color: $positive;
}
