.search-box__text-input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 0.9rem;
    color: #666666;
    box-sizing: border-box;
    padding-left: 6px;
    border-radius: 4px;
    border: 1px solid #0000001a;
}

.search-box__text-container--open {
    animation: expand .2s ease-out;
    animation-fill-mode: backwards;
    position: relative;
    /* border: 1px solid #cccccc; */
}

.search-box__text-container--closed {
    width: 0px;
    border: none;
    animation: shrink .2s ease-out;
    animation-fill-mode: forwards;
}

.search-box__container {
    display: inline-flex;
    position: relative;
    width: fit-content;
    box-sizing: border-box;
    height: 34px;
}

.search-box__loading-container {
    position: absolute;
    right: 2px;
}

.search-box__button-container { float: right }

.search-box__no-result {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #e06666;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.search-box__no-result * { 
    color: #fff;
    font-size: 14px;
};

@keyframes expand {
    0% {
      width: 0px;
    }

    100% {
      width: fit-content;
      border: 1px solid #cccccc;
    }
}

@keyframes shrink {
    0% {
        width: fit-content;
        border: 1px solid #cccccc;
    }

    100% {
        width: 0px;
    }
}
