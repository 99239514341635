@import 'config/colors.scss';

.rating {

  &__stars {
    display: flex;
    cursor: pointer;
  }
  &__star--filled {
    color: $pending;
  }
  
  &__star--empty {
    color: $ultra-light-gray;
  }
}

.rating-star {
  outline: none;
}