@import 'config/colors.scss';

.pencilIcon {
  color: #999999;
  padding-left: 8px;
  padding-top: 3px;
  height: 12px;
  margin-bottom: 5px;
}

.pencilIcon:hover {
  color: $positive;
  cursor: pointer;
}

.saveButton {
  padding: 0px;
  height: 24px;
  width: 44px;
  background-color: $positive;
  margin-left: 5px;
}

.cancelButton {
  padding: 0px;
  height: 24px;
  width: 60px;
  background-color: $positive;
  margin-left: 5px;
}

.inputEditableFieldOld {
  width: 60px;
  border-radius: 5px;
  padding-left: 5px;
}

.inputEditableFieldOld:focus {
  outline: none;
}

.inputEditableField {
  color: $positive;
  border: none;
  font-size: 16px;
}

.inputEditableField:focus{
  outline: none;
  border: none;
  /* font-size:; */
}

.inputEditableField:focus::placeholder {
  color: $text;
}

.labelText {
  font-family: Inter, sans-serif !important;
  font-weight: 700 !important;
  display: inline-block !important;
  width: 25% !important;
  text-transform: uppercase !important;
}

.inputEditableField:disabled {
  background-color: white;
  color: $accent;
}