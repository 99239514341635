@import 'config/colors.scss';

.areaNamesScroller {
  margin-top: 12px;
  margin-bottom: 12px;
  max-height: 300px;
}

.uploadProgress {
  position: absolute;
  border-radius: 6px 6px 0 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
}

.uploadProgress span {
  background-color: #33cd5f;
  height: 5px;
  transition: 0.35s width ease;
  display: block;
  border-radius: 6px 6px 0 0;
}

.uploadProgressNoEase {
  position: absolute;
  border-radius: 6px 6px 0 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
}

.uploadProgressNoEase span {
  background-color: #33cd5f;
  height: 5px;
  display: block;
  border-radius: 6px 6px 0 0;
}

.roleName {
  font-size: 0.9em;
  width: 200px;
}

.roleContainer {
  display: flex;
  flex-direction: row;
}

.roleContainer span {
  margin: 3px 0 0 50px;
}

.gsDivider {
  display: flex;
  flex-direction: row;
}

.gsLeftContainer {
  width: 50%;
  float: left;
  border-right: 1px solid #e6e6e6;
  padding: 5px;
  height: 400px;
  overflow: scroll;
}

.gsLeftContainer h3 {
  margin-left: 10px;
}

.gsRightContainer {
  width: 50%;
  float: right;
  padding: 5px;
  height: 400px;
  overflow: scroll;
}

.gsRightContainer h3 {
  margin-left: 5px;
}

.searchRGS {
  box-shadow: none;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  outline: none;
  width: 98%;
  height: 35px;
  margin: 5px;
  border-radius: 7px;
  padding: 5px;
  font-size: 1em;
}

.ms-react-select__control {
  margin-bottom: 6px;
  background-color: white !important;
}

.ms-react-select__value-container {
  cursor: pointer;
  line-height: 32px;
}

.ms-react-select__single-value {
  width: 100%;
}

.ms-react-select__option {
  color: #999999 !important;
  cursor: pointer !important;
}

.ms-react-select__option--is-selected {
  background-color: $positive !important;
  color: #fff !important;
}

.ms-react-select__control--is-focused {
  border: none !important;
}

.ms-react-select__multi-value__label {
  max-height: 20px !important;
  min-height: 20px !important;
  background-color: $positive !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ms-react-select__multi-value__remove {
  background-color: #e06666 !important;
  color: #fff !important;
  max-height: 20px !important;
  min-height: 20px !important;
}
