@import 'config/colors.scss';

.rating {

  &__stars {
    display: flex;
  }
  &__star--filled {
    color: $rating-yellow;
  }
  
  &__star--empty {
    color: $ultra-light-gray;
  }
}

.empty-container {
  position: absolute;
  height: 40px;
  width: 200px;
  cursor: default;
}

.filled-container {
  z-index: 1;
  white-space: nowrap;
  overflow-x: hidden;
  height: 40px;
  width: 200px;
  cursor: default;
}
