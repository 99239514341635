.selectedContainer {
  border: 1px solid #2485E2;
  margin: 5px;
  padding: 5px;
  border-radius: 7px;
  height: 30px;
  display: flex;
  align-items: center;
  color: #2485E2;
  transition: all .15s ease;
}

.selectedContainer:hover {
  cursor: pointer;
  background-color: #2485E2;
  color: white;
}
