@import 'config/colors.scss';

.helpCentreHeader {
  position: relative;
  padding: 12px 12px 24px 12px;
  background-color: $background-light-6;
  border-bottom: 1px solid $line;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helpCentreBackButton {
  // position: absolute;
  // top: 10px;
  // right: 12px;
  // margin-top: 12px;
}

.helpCentre {
  padding: 48px;
  overflow-y: auto;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 24px;
    color: $text;
  }
  h1 { margin-top: 0; }
  h2 { font-size: 1rem; }

  p, ul, ol {
    color: $black;
    font-size: 0.9rem;
    padding-top: 12px;
  }

  ul, ol {
    padding-left: 36px;
  }

  a.helpHeaderLink {
    display: block;
    text-decoration: none;
  }

  .helpHeader {
    margin: 0;
    padding: 24px 12px;
    border-radius: 2px;
    background-color: white;
    color: $text;
    text-decoration: none;

    svg {
      margin-right: 10px;
    }
  }

  .contentWrapper {
    font-size: 1.1em;
    line-height: 1.45;
    max-width: 45em;

    img {
      max-width: 100%;
      border-radius: 2px;
      padding-top: 12px;
    }

    table {
      margin-top: 12px;
    }

    table td, table th {
      padding: 6px;
      border: 1px solid $border;
    }

    thead th {
      border: 1px solid $border;
      background-color: #f4f4f4;
    }
  }
}
