@import 'config/colors.scss';

.dataTableLoadingView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

table.dataTable {
  width: 100%;
}

table.dataTable.dataTable--fixed {
  table-layout: fixed;
}

table.dataTable .data-table-column-header-row td {
  // position: sticky;
  z-index: 1; // Otherwise cell contents can end up on top of header
  top: 0;
  color: $text;
  background-color: white;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid $line;
  padding: 6px;
  white-space: nowrap;
  font-size: 1rem;
}

table.dataTable .data-table-column-header-row td svg {
  margin-right: 6px;
}

table.dataTable.dataTable--nowrap td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-table-row:nth-of-type(even) {
  background-color: #F9F9F9;
}
.data-table-row:nth-of-type(even):hover {
  background-color: #eaeaea;
}

.data-table-column-header:hover {
  cursor: pointer;
}


table.dataTable img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
}

table.dataTable td {
  font-size: 0.9rem;
  color: $text;
  vertical-align: middle;
  padding: 6px;
  line-height: 1.34;
}

table.dataTable td:first-of-type {
  padding-left: 12px;
}
table.dataTable td:last-of-type {
  padding-right: 12px;
}
