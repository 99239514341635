@import 'config/colors.scss';

.info-banner {
  color: white;
  background-color: $background-focussed;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 6px 12px;
  font-size: 0.9rem;
  line-height: 1.2;

  svg {
    flex: 0 0 auto;
    height: 30px;
    width: 30px;
    margin-right: 12px;
  }

  p { 
    flex: 1
  }

  &.info-banner--positive { background-color: $primary; }
  &.info-banner--warning  { background-color: $warning; }
  &.info-banner--danger   { background-color: $red; }
}

