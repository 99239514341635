@import 'config/colors.scss';

.notificationCentreContainer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  // padding-left: 12px;
  background-color: $white;
  font-size: 28px;
  z-index: 10000000; /* Stupid but needed to render above the logout button */
  transition: all 0.3s ease;
  width: 400px;
  box-sizing: border-box;
  // box-shadow: 8px 3px 6px -7px rgba(0,0,0,0.4); 
  box-shadow: -8px 3px 6px -7px rgba(0,0,0,0.4);
}

.notificationCentreContainerOpen {
  right: 0;
}

.notificationCentreClosed {
  right: -400px;
}

.notificationCentreTitleContainer {
  position: relative;
  left: 0;
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  padding: 12px;
  background-color: $background-light-6;
}

.notificationTextContainer {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  align-items: center;
  margin-top: 60%;
}

.notificationCentreCloseButton {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: $text;
  float: right;
  width: 200px;
  text-align: right;
}

.notificationCentreNotificationsContainer {
  position: relative;
  margin-left: 0;
  margin-top: 8px;
  // border-radius: 5px 0;
  height: 100%;
  background-color: #ffffff;
  color: #999999;
  // padding: 6px;
  font-size: 15px;
}

.notificationCentreCentralLoading {
  display: flex;
  height: 60%;
  justify-content: center;
  align-items: center;
}

.notificationCentreNotificationsTopContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-left: 0px;
  padding-bottom: 5px;
}

.notificationCentreClearAll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  color: $text;
  width: 100%;
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box;
  padding: 6px;
}

.filler {
  width: 100px;
}

.notificationCentreClearAll:hover {
  background-color: hsla(209, 79%, 70%, 1);
}

.emptyNotificationCentreClearAll {
  height: 29px;
}
