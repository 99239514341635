.row-container:nth-of-type(even) {
  background-color: #f9f9f9;
}

.row-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  text-align: center;
}

.row-container:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.ref-link {
  border-bottom: 1px solid #2684FF;
  color: #2684FF;
  width: 30px;
}

.ref-link:hover {
  font-weight: 500;
  cursor: pointer;
}
