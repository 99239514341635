.sandbox-container {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  z-index: 1;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  background-color: #e06666;
  color: white;
};
