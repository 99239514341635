@import 'config/colors.scss';

.defaultIconStyle {
  color: white;
  font-size: 11px;
  margin-right: 3px;
  margin-top: -2px;
}

.pill {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 120px;
  height: 24px;
  border-radius: 12px;
  white-space: nowrap;
  font-size: 0.9rem;
  color: white;
}

.pill.pill--outline {
  color: rgb(183, 183, 183);
  border: 1px solid rgb(183, 183, 183);
}

.pill.pill--positive {
  background-color: $active;
  border-color: $active;
}
.pill.pill--positive.pill--outline {
  border-color: $active;
  color: $active;
  background-color: #ffffff;
}

.pill.pill--negative {
  background-color: #B7B7B7;
  border-color: #B7B7B7;
}
.pill.pill--negative.pill--outline {
  border-color: #B7B7B7;
  color: #B7B7B7;
  background-color: #ffffff;
}

.pill.pill--external {
  background-color: #5A5AA4;
  border-color: #5A5AA4;
}
.pill.pill--external.pill--outline {
  border-color: #5A5AA4;
  color: #5A5AA4;
  background-color: #ffffff;
}

.pill.pill--create {
  background-color: $create;
  border-color: $create;
}
.pill.pill--create.pill--outline {
  border-color: $create;
  color: $create;
  background-color: #ffffff;
}

.pill.pill--warning {
  background-color: $pending;
  border-color: $pending;
}
.pill.pill--warning.pill--outline {
  color: $pending;
  background-color: #ffffff;
}

.pill.pill--danger {
  background-color: $error;
  border-color: $error;
}
.pill.pill--danger.pill--outline {
  color: $error;
  background-color: #ffffff;
}

.pill.dark {
  background-color: #000000;
  border-color: #ffffff;
}
.pill.dark.pill--outline {
  border-color: #ffffff;
  color: #ffffff;
  background-color: #000000;
}

.pill.pill--invalid {
  background-color: $invalid;
  border-color: $invalid;
}
