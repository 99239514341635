$header-main-size: 1.5rem;
$header-main-weight: 600;

$header-sub-size: 1.3rem;
$header-sub-weight: 600;

$text-main-size: 0.9rem;
$text-main-weight: 500;

$text-sub-size: 0.85rem;
$text-sub-weight: 500;

$label-size: 0.9rem;
$label-weight: 500;
