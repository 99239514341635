@import 'config/colors.scss';

.userBarButtons {
  display: flex;
}

.userBarButtons a.userBarButton {
  text-decoration: none;
  border: none;
  border-radius: 30px;
  display: block;
  box-shadow: 0 2px 5px 0 rgba(49,49,93,.1), 0 1px 2px 0 rgba(0,0,0,.08);
  width: 30px;
  height: 30px;
  background-size: cover;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.25 ease;
  z-index: 1000000;
}

.userBarButtons a.userBarButton:hover {
  opacity: 1;
}

.popover {
  position: absolute;
  z-index: 9999;
  top: 45px;
  left: 23px;
  font-size: 0.8em;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  will-change: transform,opacity,visibility;
  transition-property: transform,opacity,visibility;
  transition-duration: 0.25s;
  transition-delay: 0.1s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.popover.active {
  opacity: 1;
  transform: none;
  visibility: visible;
  left: 25px;
}

.popover .popoverInner {
  background-color: white;
  box-shadow: 0 0 0 1px rgba(136,152,170,.1), 0 15px 35px 0 rgba(49,49,93,.1), 0 5px 15px 0 rgba(0,0,0,.08);
  border-radius: 6px;
}

.popover .userDeets {
  border-bottom: 1px solid $line;
}

.popover .userDeets .email {
  color: $text;
  padding: 12px 18px;
  font-weight: 500;
  font-size: 0.9rem;
}

.popover .links .link {
  display: block;
  color: $text;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 12px 18px;
}

.popover .links .link:hover {
  background-color: $background-focussed;
  text-decoration: none;
}
